import { Button } from 'src/shadcn/components/ui/button'
import { Input } from 'src/shadcn/components/ui/input'
import { MoveUpRight } from 'lucide-react'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'

type Props = {}

const FooterMain = (props: Props) => {

    return (
        <div>
            <div className=' min-h-[350px] bg-slate-900  flex justify-center items-center'>
                <div className='grid grid-cols-2 gap-4 max-w-[80vw]'>
                    <div className='grid grid-cols-2 gap-4'>
                        <div className='space-y-2'>
                            <div className='uppercase font-bold text-primary'>Navigation</div>
                            <div><Link to={MAIN_ROUTES_CONST.welcome} ><div className='text-white'>Home</div></Link></div>
                            <div><Link to={MAIN_ROUTES_CONST.marketplace}><div className='text-white'>Marketplace</div></Link></div>
                            <div className='text-white'>Sell</div>
                        </div>
                        <div className='space-y-2'>
                            <div className='uppercase font-bold text-primary'>About Us</div>
                            <div><Link to={MAIN_ROUTES_CONST.about_us}><div className='text-white'>About Us</div></Link></div>
                            <div> <Link to={MAIN_ROUTES_CONST.contact_us}><div className='text-white'>Contact us</div></Link></div>
                            <div><Link to={MAIN_ROUTES_CONST.faq}><div className='text-white'>FAQs</div></Link></div>
                            <div className='text-white'>User Manual</div>
                        </div>
                    </div>
                    <div className='space-y-4'>
                        <div className='uppercase font-bold text-primary'>Newsletter</div>
                        <div className='text-white'>For inquiries, please only contact us via the contact form. We look forward to receiving your message.</div>
                        <Input className='bg-white' />
                        <div><Link to={MAIN_ROUTES_CONST.contact_us}> <Button className='space-x-2 rounded-full'><span><MoveUpRight className='text-white' /></span><span>Contact Us</span></Button></Link></div>
                    </div>
                </div>
            </div>
            <div className='h-[50px] bg-white  flex justify-center items-center text-xs'>
                © 2024 Orange current technologies Pvt. Ltd | All rights reserved  | Terms and conditions | Data Privacy
            </div>
        </div>
    )
}

export default FooterMain