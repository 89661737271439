import React from 'react'
import { ICON_CURLY, IMG_CROWDFUNDING_BANNER, IMG_CROWDFUNDING_BUY_ENERGY, IMG_CROWDFUNDING_CROWD_FUNDING, IMG_CROWDFUNDING_SELL_ENERGY } from 'src/helpers'
import { Button } from 'src/shadcn/components/ui'

type Props = {}

const CrowdFundingMain = (props: Props) => {
    return (
        <div>
            <div className='w-full h-[300px] overflow-hidden' style={{ backgroundImage: "url(" + IMG_CROWDFUNDING_BANNER + ")", backgroundSize: "cover" }} >
                <div className='flex flex-col justify-center items-center h-[100%]'>
                    <div className='text-primary text-4xl ' >P2P Crowd funding !!</div>
                    <div className='text-white text-xs ' >Invest in renewable energy projects in India as little as Rs 1000 !!</div>
                </div>
            </div>
            <div className='max-w-[80vw] my-[50px] space-y-[50px] mx-auto'>
                <div className='text-primary font-bold text-center'>Sell, Buy, Acquire, Market, Seek funding</div>
                <div className='grid sm:grid-cols-1  md:grid-cols-3 gap-4 mx-[32px]'>
                    <div className='h-[200px] w-[200px] overflow-hidden mx-auto'><img src={IMG_CROWDFUNDING_BUY_ENERGY} style={{ width: "100%", height: "100%" }} /></div>
                    <div className='h-[200px] w-[200px] overflow-hidden mx-auto'><img src={IMG_CROWDFUNDING_SELL_ENERGY} style={{ width: "100%", height: "100%" }} /></div>
                    <div className='h-[200px] w-[200px] overflow-hidden mx-auto'><img src={IMG_CROWDFUNDING_CROWD_FUNDING} style={{ width: "100%", height: "100%" }} /></div>
                </div>
                <div className='w-100 flex justify-center'>
                    <Button className='rounded-full mx-auto '>Go To Marketplace</Button>
                </div>
                <div className='text-center w-[80vw]'>
                    <p>Invest in renewable energy projects in India as little as</p>
                    <p className='text-primary font-bold'>Rs 1000 !!</p>
                    <p>Finance green transition by investing in sustainable crowdfunding projects. It provides you better returns on the investment while making our planet, a better place to live.
                    </p>
                    <br />
                    <p>By investing in <span className='font-bold'>solar farm projects</span> via crowdfunding, investors can make <span className='font-bold'>up to 10% -12% </span>
                        simultaneously fighting climate change and making India more energy <span className='font-bold'>independent</span>.</p>
                    <br />
                    <p>But, how to evaluate investments in <span className='font-bold'>solar energy via Crowdfunding?</span></p>
                </div>
            </div>
            <div className='max-w-[80vw] my-[24px] mx-auto bg-orange-100 p-4 border-2 border-primary rounded-lg  '>
                <div className="flex sm:flex-col lg:flex-row space-x-2 items-center " >
                    <div className='flex space-x-2 flex-1 items-center  '>
                        <div className='w-[32px] h-[32px] bg-primary rounded-full text-white flex justify-center items-center '>1</div>
                        <div className='font-bold'>How much <span className='text-primary'>energy</span> will solar project be  able to produce?</div>
                    </div>
                    <div className='lg:rotate-0 hidden lg:block'><img src={ICON_CURLY} /></div>
                    <div className='flex-1 text-xs py-4'>
                        <p>Usually, you see that Solar Project production capacity, is stated as <span className='font-bold'>Kwh</span>which means the peak power of a PV system, but as the sun is not shining <span className='font-bold'>24/7</span>, actual output will be substantially lower. </p>
                        <br />
                        <p>Therefore, if this information is not presented in the deal description, ask your marketplace to provide it.</p>
                    </div>
                </div>
                <hr className='my-2 border-primary' />
                <div className="flex sm:flex-col lg:flex-row space-x-2 items-center " >
                    <div className='flex space-x-2 flex-1 items-center border '>
                        <div className='w-[32px] h-[32px] bg-primary rounded-full text-white flex justify-center items-center '>2</div>
                        <div className='font-bold'>Will the project be able to sell <span className='text-primary'>produced energy</span> and for how much?</div>
                    </div>
                    <div className='lg:rotate-0 hidden lg:block'><img src={ICON_CURLY} /></div>
                    <div className='flex-1 text-xs py-4'>
                        <p>Solar Project can produce the energy, but will they be able to sell it?</p>
                        <p>In big scale projects, the project owners will sell energy to the grid, through <span className='font-bold'>Power purchase agreement (PPA)</span>, wherein its usually defines how much energy will be purchased, for which price, and agreement maturity.</p>
                        <br />
                        <p>In small projects, the <span className='font-bold'>commercial</span> and <span className='font-bold'>industrial</span> premises pays at the much higher rates. They sign the PPA's with the marketplace. If the marketplace does not have such an agreement yet, ask for an explanation and assurance that such an agreement will be signed.</p>
                    </div>
                </div>
                <hr className='my-2 border-primary' />
                <div className="flex sm:flex-col lg:flex-row space-x-2 items-center " >
                    <div className='flex space-x-2 flex-1 items-center border '>
                        <div className='w-[32px] h-[32px] bg-primary rounded-full text-white flex justify-center items-center '>3</div>
                        <div className='font-bold'>Will the Marketplace generate enough funds to repay  <span className='text-primary'>your loan</span> ?</div>
                    </div>
                    <div className='lg:rotate-0  hidden lg:block '><img src={ICON_CURLY} /></div>
                    <div className='flex-1 text-xs py-4'>
                        <p>At this point, you know how much the marketplace will produce and how much it will be sold. You need to understand how much annual Operation and Maintenance (O&M) costs is. Here, once again you will need to ask the marketplace, to provide you with estimated data.</p>
                        <br />
                        <p> <span className='font-bold'>(Annual energy produced (kwh) * price INR/kwh - annual O&M costs-loan service costs)</span>, If it is greater than zero, it's an indicator about safe investment!!!</p>
                    </div>
                </div>
                <hr className='my-2 border-primary' />
                <div className="flex sm:flex-col lg:flex-row space-x-2 items-center " >
                    <div className='flex space-x-2 flex-1 items-center border '>
                        <div className='w-[32px] h-[32px] bg-primary rounded-full text-white flex justify-center items-center '>4</div>
                        <div className='font-bold'>Will marketplace be able to <span className='text-primary'>deliver</span> ?</div>
                    </div>
                    <div className='lg:rotate-0 hidden lg:block'><img src={ICON_CURLY} /></div>
                    <div className='flex-1 text-xs py-4'>
                        <p> Delivery of solar project, whether small of big size, is a complex task, therefore when it comes to picking up investment projects you need to understand how qualified a team is delivering that project. Check team experience and evaluate do they have delivered such projects in past and how successful they are now.</p>
                        <br />
                        <p>We at <span className='font-bold'>Electreecity marketplace</span>, are trying to provide you with tips on how to make successful investments by provide you with all answers and details, through <span className='font-bold'>fully automated blockchain</span> enabled marketplace !!!!</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CrowdFundingMain