
import { ReactNode } from "react"
import { cn } from "src/helpers"
import { ClassNameValue } from "tailwind-merge"
 
export function ChartDisplay({
    children,
    className,
}: { children: ReactNode, className?: ClassNameValue } & React.ComponentProps<"div">) {
    return (
        <div
            className={cn(
                "themes-wrapper group relative flex flex-col overflow-hidden rounded-xl border shadow transition-all duration-200 ease-in-out hover:z-30",
                className
            )}
        >
            <div className="relative z-10 [&>div]:rounded-none [&>div]:border-none [&>div]:shadow-none">
                {children}
            </div>
        </div>
    )
}