import React from 'react'
import { BsPatchCheckFill } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { MAIN_ROUTES_CONST } from 'src/navigations/modules';
import RoundedButton from 'src/shadcn/components/custom/RoundedButton';

type Props = {}

const SuccessResetPassoword = (props: Props) => {
    return (
        <div className=''>
            <div className='w-[500px] mx-auto p-[48px] shadow-xl my-[50px]'>
                <div className='flex flex-col justify-center items-center  space-y-4 '>
                    <BsPatchCheckFill size={48} className='text-primary' />
                    <div className='uppercase font-bold'>Successful</div>
                    <div className='text-sm'>You can now use your password to log in to your account</div>
                    <div className='w-full'><Link to={MAIN_ROUTES_CONST.welcome} ><RoundedButton className='w-full' >Login Now</RoundedButton></Link></div>
                </div>
            </div>
        </div>
    )
}

export default SuccessResetPassoword