import React from 'react'

type Props = {}

const SellMain = (props: Props) => {
  return (
    <div className='text-xl font-bold text-gray-200 flex justify-center items-center w-full h-[500px] '>
        Coming Soon
    </div>
  )
}

export default SellMain