import { AxiosResponse } from "axios";
import { IEnquiryForm1 } from "src/modules/EnquiryForms/EnquiryForm1/modules";
import { IEnquiryForm2 } from "src/modules/EnquiryForms/EnquiryForm2/modules";
import { axiosInstance } from "src/services/axiosSetup";
import * as API_URL from "src/services/consts/Projects"


export function getAllProjectsRequestApi(query?: string): Promise<any> {
    query = query ? `${API_URL.GET_ALL_PROJECTS}?${query}` : API_URL.GET_ALL_PROJECTS
    return axiosInstance.get(query).then((d: AxiosResponse) => d.data);
}

export function getProjectDetailsByIDRequestApi(id: string): Promise<any> {
    return axiosInstance.get(`${API_URL.GET_PROJECT_DETAILS}/${id}`).then((d: AxiosResponse) => d.data);
}

export function createNewProjectEnquiryRequestRequestApi(data: IEnquiryForm1): Promise<any> {
    return axiosInstance.get(`${API_URL.CREATE_NEW_PROJECT_REQUEST}`).then((d: AxiosResponse) => d.data);
}
export function createExistingProjectEnquiryRequestRequestApi(data: IEnquiryForm2): Promise<any> {
    let postData = {
        "project_id": data.project_id,
        "name": data.contactPersonName,
        "phoneNumber": data.contactPersonNumber,
        "email": data.emailAddress,
        "enquiry": data.enquiry,
        "unit_consumption_per_day": data.unitConsumptionPerDay,
        "description": data.projectDescription
    }
    return axiosInstance.post(`${API_URL.CREATE_EXISTING_PROJECT_REQUEST}`,postData).then((d: AxiosResponse) => d.data);
}

