import React from 'react'
import { useAuth } from '../Auth/Core'
import { LockKeyhole } from 'lucide-react'

type Props = {}

const RenderData = ({ value }: { value: string | undefined }) => {
    const { currentUser } = useAuth()
    return currentUser ? <span>{value || "-"}</span> : <LockKeyhole size={16} />
}

export default RenderData