import { Navigate, Route, Routes } from 'react-router-dom'
import CellOverview from 'src/modules/Cell/CellOverview'
import InvestmentCalculator from 'src/modules/Cell/InvestmentCalculator'
import MakePayment from 'src/modules/Cell/MakePayment'
import ProjectRightDetail from 'src/modules/Cell/ProjectRightDetail'
import CellLayout from 'src/modules/Layouts/CellLayout'
import { MAIN_ROUTES_CONST } from './modules'

type Props = {}

export const CELL_ROUTES = {
    details: "details",
    investment_calculator: "investment-calculator",
    review: "review",
    payment: "payment",
}

const CellRoutes = (props: Props) => {
    return (
        <Routes>
            <Route element={<CellLayout />}>
                <Route index element={<ProjectRightDetail />} />
                <Route path={CELL_ROUTES.details + "/:id"} element={<ProjectRightDetail />} />
                <Route path={CELL_ROUTES.investment_calculator + "/:id"} element={<InvestmentCalculator />} />
                <Route path={CELL_ROUTES.review + "/:id"} element={<CellOverview />} />
                <Route path={CELL_ROUTES.payment + "/:id"} element={<MakePayment />} />
                <Route path={"*"} element={<Navigate to={'/' + MAIN_ROUTES_CONST.cell + "/"} />} />
            </Route>
        </Routes>
    )
}

export default CellRoutes