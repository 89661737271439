import { FileCog, FileSearch, HandCoins, Package, Siren, Wallet, IndianRupee } from 'lucide-react'
import { GiReceiveMoney } from "react-icons/gi";

export const INVESTOR_ANALYSIS_DATA = [
  {
    title: "Total Investment",
    dataKey: "totalInvestment",
    prefix: "₹",
    value: "0",
    icon: <Wallet size={24} className='text-gray-500' />
  },
  {
    title: "Total Return",
    dataKey: "totalReturns",
    prefix: "₹",
    value: "0",
    icon: <HandCoins size={24} className='text-gray-500' />
  },
  {
    title: "Total Project",
    dataKey: "totalProjects",
    prefix: "#",
    value: "0",
    icon: <Package size={24} className='text-gray-500' />
  },
]


export const OFFTACKER_ANALYSIS_DATA = [
  {
    title: "Total Loan",
    dataKey: "totalInvestment",
    prefix: "₹",
    value: "0",
    icon: <Wallet size={24} className='text-gray-500' />
  },
  {
    title: "Total Pending Amount",
    dataKey: "totalInvestment",
    prefix: "₹",
    value: "0",
    icon: <IndianRupee size={24} className='text-gray-500' />
  },
  {
    title: "Total Pending EMI",
    dataKey: "totalInvestment",
    prefix: "",
    value: "0",
    icon: <GiReceiveMoney size={24} className='text-gray-500' />
  },
  // {
  //   title: "Pending Request",
  //   value: "₹ 150000",
  //   icon: <Hourglass size={20}  className='text-gray-500' />
  // }
]


