import React, { ReactNode, useMemo } from 'react'
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../ui"
import { cn } from 'src/helpers'
import { ClassNameValue } from 'tailwind-merge'

type Props = {
    isOpen: boolean
    dialog_header?: string
    dialog_description?: string
    children?: ReactNode
    className?: ClassNameValue,
    onChangeIsOpen?: (e: any) => void

}

const Modal = ({ isOpen, dialog_header, dialog_description, children, className, onChangeIsOpen }: Props) => {

    return (
        <Dialog open={isOpen} modal onOpenChange={onChangeIsOpen} >
            <DialogContent className={cn(className)}>
                <DialogHeader>
                    <DialogHeader>
                        <DialogTitle>{dialog_header}</DialogTitle>
                        <DialogDescription>{dialog_description}</DialogDescription>
                    </DialogHeader>
                </DialogHeader>
                {children}
            </DialogContent>
        </Dialog>

    )
}

export default Modal