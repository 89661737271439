import React, { useEffect, useMemo, useState } from 'react'
import { BsBookmarkCheckFill } from "react-icons/bs";
import { BUILDING_TYPE_ICONS, catchAsync, checkDecimal, formatIndianNumber, ICON_OCT_VERIFIED, ICON_PROJECT_SIZE, ICON_PROJECT_TYPE, ICON_ROOF_TOP, PROPERTY_TYPE_ICONS } from 'src/helpers';
import { IoIosLock } from "react-icons/io";
import { getAllProjectsRequestApi } from 'src/services/requests/Projects';
import { AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import { MAIN_ROUTES_CONST } from 'src/navigations/modules';
import { CELL_ROUTES } from 'src/navigations/CellRoutes';
import { useAuth } from 'src/modules/Auth/Core';
import { Calendar, IndianRupee, Percent } from "lucide-react";
import { Separator } from 'src/shadcn/components/ui';
import CircularProgressBar from 'src/shadcn/components/custom/CircularProgressBar';

type Props = {
    projects: Array<any>
    loading: boolean
}


type RenderItemProps = {
    projectName: string,
    _id: string,
    projectSize: string,
    projectType: string,
    systemType: string,
    totalProjectCost: string,
    returnPerYearRate: string
    headerImage: any,
    termPlan: string,
    project_id: string,
    imagesAttachments: string[],
    requiredInvestment: string,
    totalInvestmentValue: string,
    buildingType: string
}

const RenderItem = ({ projectName, _id, projectSize, projectType, systemType, totalProjectCost, headerImage, returnPerYearRate, termPlan, project_id, imagesAttachments, requiredInvestment, totalInvestmentValue, buildingType }: RenderItemProps) => {

    const navigate = useNavigate()

    const { currentUser } = useAuth()

    const investmentPercentage = useMemo(() => {
        if (requiredInvestment && totalInvestmentValue) {
            console.log({totalInvestmentValue, requiredInvestment});
            let newTotalInvestmentValue = parseFloat(totalInvestmentValue) || 0;
            let newRrequiredInvestment = parseFloat(requiredInvestment) || 0;
            console.log({newTotalInvestmentValue, newRrequiredInvestment});
            
            let Percent = ((newTotalInvestmentValue /newRrequiredInvestment) * 100)
            if(Percent <= 0){
                Percent = 0
            }
            else if(Percent >= 100) {
                Percent = 100
            }
            return Percent.toFixed(0)
        }
        return 0
    }, [totalInvestmentValue, requiredInvestment])

    console.log({totalInvestmentValue, requiredInvestment,});
    


    return <div onClick={() => navigate("/" + MAIN_ROUTES_CONST.cell + "/" + CELL_ROUTES.details + "/" + _id)} className='w-100 h-[220px] grid grid-cols-4 gap-4 border border-gray-200 hover:shadow-xl hover:bg-orange-100 cursor-pointer rounded-lg  '>
        <img style={{ width: "100%", height: '218px' }} src={imagesAttachments?.length > 0 ? imagesAttachments[0] : ""} />
        <div className='col-span-3 space-y-4 p-4'>
            <div className='flex justify-between'>
                <div className='flex flex-col'>
                    <span className='font-bold uppercase text-primary'>{projectName}</span>
                    <div className='flex space-x-2 items-center'>
                        <span className='text-xs'># {project_id}</span>
                        <span><BsBookmarkCheckFill size={16} className='text-primary' /></span>
                    </div>
                </div>
                <div className='flex items-center space-x-2'>
                    {Number(totalInvestmentValue) == 0 ? <span className='rounded-full bg-green-600 font-semibold p-2 text-xs text-white uppercase px-4' >New</span> : <span className='rounded-full bg-red-600 font-semibold p-2 text-xs text-white uppercase' >In-Progress</span>}
                </div>
            </div>
            {/* <div className="flex space-x-2">
                <div className='text-xs bg-gray-200 rounded-full py-1 px-2 space-x-1'><span className='text-primary uppercase'>IRR</span><span>{returnPerYearRate} %</span></div>
                <div className='text-xs bg-gray-200 rounded-full py-1 px-2 space-x-1'><span className='text-primary uppercase'>TERM PLAN</span><span>{termPlan} Months</span></div>
            </div> */}
            <div className='grid grid-cols-4 gap-4'>
                <div className='col-span-3 flex justify-center  '>
                    <div className='flex flex-col items-center space-y-1.5 px-4'>
                        <div><img src={ICON_PROJECT_SIZE} style={{ height: "24px", width: "15px" }} /></div>
                        <div className=' font-bold'>{projectSize} kWp</div>
                        <div className='text-xs'>Project Size</div>
                    </div>
                    <Separator orientation='vertical' />
                    <div className='flex flex-col items-center space-y-1.5 px-4'>
                        <div><img src={PROPERTY_TYPE_ICONS[projectType as keyof object]} style={{ height: "24px", width: "24px" }} /></div>
                        <div className=' font-bold'> {projectType}</div>
                        <div className='text-xs'>Project Type </div>
                    </div>
                    {/* <Separator orientation='vertical' />
                    <div className='flex flex-col items-center space-y-1.5 px-4'>
                        <div><img src={PROPERTY_TYPE_ICONS[buildingType as keyof object]} style={{ height: "24px", width: "24px" }} /></div>
                        <div className=' font-bold'> {buildingType}</div>
                        <div className='text-xs'>Building Type </div>
                    </div> */}
                </div>
                <div className=''>
                    <div className='flex flex-col items-center space-y-1.5 px-4'>
                        <div className=' text-2xl font-semibold'><span className='text-xs'>IRR</span> {returnPerYearRate} %</div>
                        <div className='font-semibold text-md'>{termPlan ? Number(termPlan) / 12 : 0} Years</div>
                    </div>
                </div>
            </div>
            <div className='flex justify-between '>
                <span className='bg-slate-200 h-10  flex items-center rounded-full p-2 space-x-1' >
                    <img src={ICON_OCT_VERIFIED} style={{ height: "24px" }} />
                    <span className='text-sm'>OCT Verified</span>
                </span>
                <div>
                    <span className='relative rounded-full bg-orange-100 pl-[50px] pr-2 py-2 text-sm  flex items-center  text-nowrap mx-auto'>
                        <span className='absolute -top-1 -left-1'> <CircularProgressBar sqSize={46} strokeWidth={5} percentage={Number(investmentPercentage)} /></span>
                        <div className='flex items-center space-x-1 text-sm'>
                            <IndianRupee size={16} /> <span className=''>{currentUser ? checkDecimal(totalProjectCost, true) : <div className='flex items-center space-x-1'><span>Investment</span> <IoIosLock size={18} /></div>} </span>
                        </div>
                    </span>
                </div>

            </div>
        </div>
    </div>
}

const ResultFilter = ({ projects, loading }: Props) => {

    return (
        <div className='flex flex-col space-y-2'>
            {/* <RenderItem />
            <RenderItem /> */}
            {
                projects.length > 0 && projects.map((project: any) => {
                    return <RenderItem {...project} />
                })
            }
        </div>
    )
}

export default ResultFilter