import { AxiosResponse } from 'axios'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { catchAsync, successTheme } from 'src/helpers'
import { CELL_ROUTES } from 'src/navigations/CellRoutes'
import { APP_ROUTES_CONST, MAIN_ROUTES_CONST } from 'src/navigations/modules'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { INIT_CREATE_PAYMENT_REQUEST } from './module'
import { createPaymentRequestRequestApi, updatePaymentStatusRequestRequestApi } from 'src/services/requests/Payments'
import { ICreatePaymentResponse } from 'src/services/requests/Payments/modules'
import { toast } from 'sonner'
import { useDispatch } from 'react-redux'
import { resetTransactionID, setTransactionID } from 'src/redux/reducers/tempDataSlice'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import { IApiResponse } from 'src/services/axiosConsts'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/store'
import { getProjectDetailsRootState, resetInvestment, resetProjectDetails } from 'src/redux/reducers/projectDetailsSlice'
import { useAuth } from '../Auth/Core'

type Props = {}

const MakePayment = (props: Props) => {

    const { id } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { currentUser } = useAuth()
    const { project_details, calculateInvestment }: any = useSelector((state: RootState) => getProjectDetailsRootState(state))
    const [initiatePaymentIsLoading, setinitiatePaymentIsLoading] = useState(false)
    const [updatePaymentIsLoading, setupdatePaymentIsLoading] = useState(false)

    useEffect(() => {
        if (Object.entries(calculateInvestment).some(([key, value]) => value == null)) {
            navigate("/")
        }
        return () => { }
    }, [calculateInvestment])


    const handleInitiatePayment = catchAsync(async () => {
        setinitiatePaymentIsLoading(true)
        return currentUser?.id && calculateInvestment.totalInvestment && await createPaymentRequestRequestApi({
            user_id: currentUser?.id,
            project_id: project_details.project_id,
            vendor_id: "ghdsghdsvhd",
            amount: calculateInvestment.totalInvestment.toString(),
            currency: "inr",
            payment_method: {
                type: "creditcard",
                card_number: "0987654",
                expiration_date: "12/25",
                cvv: "455"
            }
        })
    }, (result: ICreatePaymentResponse) => {
        console.log({ result });
        if (result.status == 201) {
            setinitiatePaymentIsLoading(false)
            toast.success("Payment Created Successful.", { style: successTheme })
            dispatch(setTransactionID(result.transaction_id))
            handleUpdatingPaymentStatus(result.transaction_id)
        }
    }, () => {
        setinitiatePaymentIsLoading(false)
    })

    const handleUpdatingPaymentStatus = catchAsync(async (trasactionID: string) => {
        setupdatePaymentIsLoading(true)
        return currentUser && calculateInvestment.totalInvestment && await updatePaymentStatusRequestRequestApi({
            userId: currentUser?.id,
            investmentAmount: calculateInvestment.totalInvestment,
            project_id: project_details.project_id,
            projectId: project_details._id,
            transaction_id: trasactionID,
            status: "success"
        })
    }, (result: IApiResponse) => {
        console.log({ result });
        if (result.status == 200) {
            setupdatePaymentIsLoading(false)
            toast.success("Payment Successful.", { style: successTheme })
            dispatch(resetTransactionID())
            dispatch(resetInvestment())
            dispatch(resetProjectDetails())
            navigate("/" + MAIN_ROUTES_CONST.marketplace)
        }
    }, () => {
        setupdatePaymentIsLoading(false)
    })

    const isLoading = useMemo(() => initiatePaymentIsLoading || updatePaymentIsLoading, [initiatePaymentIsLoading, updatePaymentIsLoading])
    const LoadingMessage = useMemo(() => {
        if (initiatePaymentIsLoading) {
            return "Initiating Payment...."
        }
        else if (updatePaymentIsLoading) {
            return "Updating Payment...."
        }
    }, [initiatePaymentIsLoading, updatePaymentIsLoading])

    return (
        <div className='space-y-4 p-4 bg-orange-50'>
            <div className='uppercase text-primary font-semibold border-primary border-b-2 border-l-0 border-r-0 border-t-0 '>SELECT A PAYMENT METHOD</div>
            <div>
                <div className='h-[150px] mx-auto border flex justify-center items-center text-gray-400'>Payment Module</div>
            </div>
            <div className=' flex justify-center space-x-2'>
                <RoundedButton isLoading={isLoading} className='w-[150px]' onClick={() => navigate(-1)} variant={'outline'}>Back</RoundedButton> <RoundedButton isLoading={isLoading} onClick={handleInitiatePayment} className='w-[150px]'>Pay Now</RoundedButton>
                <LoadingDialog isOpen={isLoading} message={LoadingMessage} />
            </div>

        </div>
    )
}

export default MakePayment