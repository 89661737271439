
import React from "react";
import { cn } from "src/helpers";
import MultiFileUpload from "./MultiFileUpload";
import { Label } from "../../ui";
import { ClassNameValue } from "tailwind-merge";

type Props = {
  formik: any;
  label: string;
  nameKey: string;
  required?: boolean;
  disabled?: boolean;
  value?: any[],
  onChange?: (e: any) => void;
  labelStyle?: ClassNameValue
};

const FormikMUltiFIleUpload = ({
  formik,
  label,
  nameKey,
  required,
  disabled,
  value,
  onChange,
  labelStyle
}: Props) => {
  return (
    <>
    
      <div className="mt-2">
        <MultiFileUpload label={label} required={required} labelStyle={labelStyle} value={formik.values[nameKey] || value || []} onChange={(e: any) => {
          // console.log({ multifile: e });
          onChange && onChange(e)
        }} />
      </div>
      {formik.touched[nameKey] && formik.errors[nameKey] && (
        <p className={cn("text-xs font-medium text-destructive")}>
          {formik.errors[nameKey]}
        </p>
      )}
    </>
  );
};

export default FormikMUltiFIleUpload;
