import { AxiosResponse } from "axios";
import { IForgotForm, ILoginForm, IRegistrationForm } from "src/modules/Auth/modules";
import { axiosInstance, axiosInstanceWithoutAuth } from "src/services/axiosSetup";
import * as API from "src/services/consts/Auth";
import { ILoginResponse } from "./modules";
import { IChangePassword, IProfile } from "src/modules/Profile/modules";
import { getAuth } from "src/modules/Auth/Core";

export function loginRequestApi({ email, password }: ILoginForm): Promise<ILoginResponse> {
    return axiosInstanceWithoutAuth.post(API.URL_LOGIN, { email, password }).then((d: AxiosResponse) => d.data);
}

export function getUserByTokenRequestApi(): Promise<ILoginResponse> {
    return axiosInstance.get(API.URL_USER_BY_TOKEN,).then((d: AxiosResponse) => d.data);
}

export function registrationRequestApi({ email, password, role }: IRegistrationForm): Promise<any> {
    return axiosInstanceWithoutAuth.post(API.URL_REGISTRATION, { email, password, role }).then((d: AxiosResponse) => d.data);
}

export function forgetpasswordRequestApi({ email }: IForgotForm): Promise<any> {
    return axiosInstanceWithoutAuth.post(API.URL_FORGOT, { email }).then((d: AxiosResponse) => d.data);
}

export function verifyOtpRequestApi({ otp, email }: { otp: string, email: string }): Promise<any> {
    return axiosInstanceWithoutAuth.post(API.URL_VERIFY_OTP, { email, otp }).then((d: AxiosResponse) => d.data);
}

export function changePasswordRequestApi({ email, password }: IChangePassword): Promise<any> {
    return axiosInstance.post(API.URL_CHANGE_PASSWORD, { email, password }).then((d: AxiosResponse) => d.data);
}

export function otpResentRequestApi({ email }: { email: string }): Promise<any> {
    return axiosInstanceWithoutAuth.post(API.URL_RESEND_OTP, { email }).then((d: AxiosResponse) => d.data);
}

export function getUserProfileRequestApi(): Promise<any> {
    const auth = getAuth()
    return axiosInstance.get(`${API.URL_FETCH_USER_PROFILE_DETAILS}/${auth?.user_id}`).then((d: AxiosResponse) => d.data);
}

export function updateUserProfileRequestApi(data: IProfile): Promise<any> {
    const auth = getAuth()
    return axiosInstance.put(API.URL_UPDATE_USER_PROFILE_DETAILS, { ...data, _id: auth?.user_id }).then((d: AxiosResponse) => d.data);
}