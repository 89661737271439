import { columns } from 'src/modules/Dashboard/AllInvestments/Table/components/columns'
import { DataTable } from 'src/modules/Dashboard/AllInvestments/Table/components/data-table'
import AnalysisTiles from 'src/modules/Dashboard/AnalysisTiles'
import InverstmentSummary from 'src/modules/Dashboard/InverstmentSummary'
import InvestmentAnalysis from 'src/modules/Dashboard/InvestmentAnalysis'
import { ChartDisplay } from 'src/shadcn/components/custom/ChartDispaly'
import { INVESTOR_ANALYSIS_DATA } from './modules'
import { useEffect, useState } from 'react'
import { catchAsync } from 'src/helpers'
import { getInvestorHistoryRequestApi, getInvestorSummaryRequestApi } from 'src/services/requests/dashboard'
import { IInvestorHistoryResponse, IInvestorSummaryResponse } from 'src/services/requests/dashboard/modules'
import { Separator, Tabs, TabsContent, TabsList, TabsTrigger } from 'src/shadcn/components/ui'

type Props = {}

const InvestorDashboard = (props: Props) => {

    const [summaryData, setSummaryData] = useState(INVESTOR_ANALYSIS_DATA)
    const [historyData, sethistoryData] = useState<any>([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        handleSummaryApi()
        handleHistoryApi()
        return () => { }
    }, [])


    const handleSummaryApi = catchAsync(async () => {
        setIsLoading(true)
        setSummaryData(INVESTOR_ANALYSIS_DATA)
        return await getInvestorSummaryRequestApi()

    }, (result: IInvestorSummaryResponse) => {
        if (result.status == 200) {
            let newData = summaryData.map((d: any) => {
                return {
                    ...d,
                    value: result.data[d.dataKey as keyof object]
                }
            })
            setSummaryData(newData)
            setIsLoading(false)
        }
    }, () => { setIsLoading(false) })

    const handleHistoryApi = catchAsync(async () => {
        setIsLoading(true)
        sethistoryData([])
        return await getInvestorHistoryRequestApi()

    }, (result: IInvestorHistoryResponse) => {
        if (result.status == 200) {
            result.data && sethistoryData(result?.data)
            setIsLoading(false)
        }
    }, () => { setIsLoading(false) })



    return (
        <div >
            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-6 my-4">
                <div className='col-span-4 space-y-4'>
                    <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
                        {
                            summaryData.map((d: any) => {
                                return <AnalysisTiles {...d} />
                            })
                        }
                    </div>
                    <div className='grid grid-cols-1'>
                        <ChartDisplay >
                            <InvestmentAnalysis />
                        </ChartDisplay>
                    </div>
                </div>
                <div className='p-8 col-span-2 border rounded-xl'>
                    <InverstmentSummary />
                </div>
            </div>
            <div className='my-4 flex justify-between '>
                <div className='text-sm font-bold uppercase text-primary'>History</div>
                <div className='flex space-x-4'>
                    <Tabs defaultValue="investments" >
                        <TabsList>
                            <TabsTrigger className='text-xs' value="investments">Investments</TabsTrigger>
                            <TabsTrigger className='text-xs' value="returns">Returns</TabsTrigger>
                        </TabsList>
                    </Tabs>
                    <Separator orientation='vertical' />
                    <Tabs defaultValue="monthly" >
                        <TabsList>
                            <TabsTrigger className='text-xs' value="monthly">Monthly</TabsTrigger>
                            <TabsTrigger className='text-xs' value="yearly">Yearly</TabsTrigger>
                        </TabsList>
                    </Tabs>
                </div>
            </div>
            <div>
                <DataTable columns={columns} data={historyData} />
            </div>
        </div>
    )
}

export default InvestorDashboard