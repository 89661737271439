import { Calendar, IndianRupee, Percent } from "lucide-react";
import { FormField } from "src/helpers";
import * as Yup from "yup"


export interface IInvestmentCalculatorForm {
    investmentAmount: string,
    finalReturn: string,
    yearTillReturn: string
}

export interface IInvestmentDetailsForm {
    investmentAmount: string,
    finalReturn: string,
    yearTillReturn: string
}

export const investmentCalculatorFormValidationSchema = Yup.object().shape({
    investmentAmount: Yup.number()
        .positive('Investment amount must be a positive number')
        .required('Investment amount is required'),
    finalReturn: Yup.number()
        .positive('Final return must be a positive number')
        .required('Final return is required'),
    yearTillReturn: Yup.number()
        .integer('Year till return must be an integer')
        .positive('Year till return must be a positive number')
        .required('Year till return is required'),
});


export const INVESTMENT_CALCULATOR_FORM_FIELDS: FormField[] = [
    {
        label: 'Investment Amount',
        dataKey: 'investmentAmount',
        componentType: 'input',
        componentProps: {
            type: 'text',
            regex: /[^0-9]+/g,
            inputPrefixIcon: <IndianRupee className='text-gray-500' size={16} />,
            numberFormatted:true,
            placeholder:"0"
        }
    },
];



export const INVESTMENT_RESULT_FORM_FIELDS: FormField[] = [
    
    {
        label: 'Annualized ROI',
        dataKey: 'annualRoi',
        componentType: 'input',
        componentProps: {
            type: 'text',
            regex: /[^0-9]+/g,
            inputSuffixIcon: <Percent className='text-gray-500' size={16} />,
            disabled: true,
            numberFormatted:true,
            placeholder:"0"
        }
    },
    {
        label: 'Expected Return Per Year ',
        dataKey: 'expectedReturnPerYear',
        componentType: 'input',
        componentProps: {
            type: 'text',
            regex: /[^0-9]+/g,
            inputPrefixIcon: <IndianRupee className='text-gray-500' size={16} />,
            disabled:true,
            numberFormatted:true,
            placeholder:"0"
        }
    },
    {
        label: 'Year Till Return',
        dataKey: 'yearTillReturn',
        componentType: 'input',
        componentProps: {
            type: 'text',
            regex: /[^0-9]+/g,
            inputSuffixIcon: <Percent className='text-gray-500' size={16} />,
            disabled:true,
            numberFormatted:true,
            placeholder:"0"
        }
    },
    {
        label: 'Investment Gain Per Year (₹)  ',
        dataKey: 'investmentGainPerYear',
        componentType: 'input',
        componentProps: {
            type: 'text',
            regex: /[^0-9]+/g,
            inputPrefixIcon: <IndianRupee className='text-gray-500' size={16} />,
            disabled:true,
            numberFormatted:true,
            placeholder:"0"
        }
    },

    {
        label: 'Total Return',
        dataKey: 'totalReturn',
        componentType: 'input',
        componentProps: {
            type: 'text',
            regex: /[^0-9]+/g,
            inputPrefixIcon: <IndianRupee className='text-gray-500' size={16} />,
            disabled: true,
            numberFormatted:true,
            placeholder:"0"
        }
    }
];