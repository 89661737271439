import { IMG_DISCOVERS_BG } from 'src/helpers'
import React from 'react'
import { Button } from 'src/shadcn/components/ui/button'
import { MoveUpRight } from 'lucide-react'

type Props = {}

const DiscoversSection = (props: Props) => {
    return (
        <div className='h-[400px] flex flex-col justify-center items-center space-y-4' style={{ backgroundImage: "url(" + IMG_DISCOVERS_BG + ")", backgroundSize: "cover" }}>
            <div className=' text-4xl font-bold text-center uppercase'>Discover the <span className='text-primary'>innovative solar solution</span> <br /> without <span className='text-primary'>any hassle</span></div>
            <div>Tell us just four things about your building and we can show you  solutions most suited for you</div>
            <Button className='space-x-2 rounded-full'><span><MoveUpRight className='text-white' /></span><span>Contact Us</span></Button>
        </div>
    )
}

export default DiscoversSection