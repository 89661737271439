import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { catchAsync, SESSIONS, successTheme } from 'src/helpers'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import { getTempDataRootState, resetEmailForValidatOtp } from 'src/redux/reducers/tempDataSlice'
import { RootState } from 'src/redux/store'
import { otpResentRequestApi, verifyOtpRequestApi } from 'src/services/requests/Auth'
import OtpInput from 'src/shadcn/components/custom/OtpInput'
import OtpInputComponent from 'src/shadcn/components/custom/OtpInputField/OtpInputComponent'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { Label } from 'src/shadcn/components/ui'

type Props = {}

const VerifyOtp = (props: Props) => {

    const [isLoading, setisLoading] = useState(false)
    const [resentOtpisLoading, setresentOtpisLoading] = useState(false)
    const [otp, setotp] = useState("")
    const [timer, setTimer] = useState(0);
    const { email_for_validate_otp, session } = useSelector((state: RootState) => getTempDataRootState(state))
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [canResend, setCanResend] = useState(false);

    useEffect(() => {
        if (!session || !email_for_validate_otp) {
            navigate("/")
        }
        return () => { }
    }, [session, email_for_validate_otp])

    useEffect(() => {
        startTimer()
        return () => { }
    }, [])



    const startTimer = async () => {
        try {
            // Start the timer after OTP is sent successfully
            setTimer(180); // 3 minutes in seconds
            const countdown = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer <= 1) {
                        clearInterval(countdown);
                        setCanResend(true);
                        return 0;
                    }
                    return prevTimer - 1;
                });
            }, 1000);

        } catch (error) {
            console.error('Failed to send OTP:', error);
            // Handle error, possibly allowing a retry
        }
    };



    const handleVerifyOtp = catchAsync(async () => {
        setisLoading(true)
        return await verifyOtpRequestApi({ otp: otp, email: email_for_validate_otp })
    }, (result: AxiosResponse) => {
        console.log({ result });
        if (result.status == 200) {
            setisLoading(false)
            if (session == SESSIONS.FORGOT_PASSWORD) {
                navigate("/" + MAIN_ROUTES_CONST.reset_password)
            }
            else if (session == SESSIONS.REGISTRATION) {
                toast.success("OTP Verified successful, You can login now.", { style: successTheme })
                dispatch(resetEmailForValidatOtp())
                navigate("/")
            }
        }
    }, () => {
        setisLoading(false)
    })

    const ResendOTP = catchAsync(async () => {
        setresentOtpisLoading(true)
        return await otpResentRequestApi({ email: email_for_validate_otp })
    }, (result: AxiosResponse) => {
        if (result.status == 200) {
            setresentOtpisLoading(false)
            setCanResend(false)
            startTimer()
            toast.success("OTP Sent successfully, please check your inbox", { style: successTheme })
        }
    }, () => { setresentOtpisLoading(false) })


    return (
        <div>
            <div className='space-y-4'>
                <div className=' font-bold uppercase text-primary'>VERIFY OTP</div>
                <div className='text-xs'>You got OTP via email please check your inbox</div>
                {!canResend ? <div className='space-y-2'>
                    <div>
                        <Label>OTP</Label>
                        <OtpInput disabled={canResend} onChange={(e: any) => setotp(e)} />
                        {/* <OtpInputComponent otpLength={6} onchange={(e:any)=> console.log(e) } /> */}

                    </div>
                    <div></div>
                </div> : null}
                <div className="timer">
                    {canResend ? (
                        <RoundedButton isLoading={resentOtpisLoading} onClick={ResendOTP} className='w-full' >Re-Send OTP</RoundedButton>
                    ) : (
                        <>
                            <RoundedButton isLoading={isLoading} onClick={handleVerifyOtp} className='w-full' >Verify OTP : <span className='mx-2'>{timer > 0 ? <>{Math.floor(timer / 60)}:{timer % 60 < 10 ? `0${timer % 60}` : timer % 60}</> : null  } </span> </RoundedButton> 
                        </>
                    )}
                </div>
                <div></div>
            </div>
        </div>
    )
}

export default VerifyOtp