import { useFormik } from 'formik'
import { FormField } from 'src/helpers'
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { Label } from 'src/shadcn/components/ui'
import { INVESTMENT_CALCULATOR_FORM_FIELDS, INVESTMENT_DETAILED_CHARGES_FORM_FIELDS, INVESTMENT_RESULT_FORM_FIELDS, InvestmentConfirmFormResultInit } from './module'
import { useNavigate, useParams } from 'react-router-dom'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import { CELL_ROUTES } from 'src/navigations/CellRoutes'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/store'
import { getProjectDetailsRootState } from 'src/redux/reducers/projectDetailsSlice'
import { useEffect } from 'react'

type Props = {}

const CellOverview = (props: Props) => {

    const { id } = useParams()

    const { calculateInvestment, project_details } = useSelector((state: RootState) => getProjectDetailsRootState(state))
    const { expectedReturnPerYear, totalInvestment, yearTillReturn, totalInterestReturn, investmentGain, finalReturn } = calculateInvestment


    useEffect(() => {
        if (Object.entries(calculateInvestment).some(([key, value]) => value == null) || !project_details) {
            navigate("/")
        }
        if (project_details) {
            formik.setFieldValue("projectCost", project_details["projectCost"] || 0)
            formik.setFieldValue("totalProjectCost", project_details["totalProjectCost"] || 0)
            formik.setFieldValue("pricePerKwh", project_details["pricePerKwh"] || 0)
        }
        return () => { }
    }, [calculateInvestment,project_details])

    const navigate = useNavigate()
    const formik = useFormik({
        initialValues: {
            ...InvestmentConfirmFormResultInit,
            expectedReturnPerYear, totalInvestment, yearTillReturn, totalInterestReturn, investmentGain, finalReturn
        },
        onSubmit(values, formikHelpers) {

        },
    })


    return (
        <div className='space-y-4 p-4 bg-orange-50'>
            <div className='uppercase text-primary font-semibold border-primary border-b-2 border-l-0 border-r-0 border-t-0 '>Details & Charges</div>
            <div className=''>
                {
                    INVESTMENT_DETAILED_CHARGES_FORM_FIELDS && INVESTMENT_DETAILED_CHARGES_FORM_FIELDS.map((field: FormField, indx: number) => {
                        let oldFiled = { ...field };
                        delete oldFiled["label" as keyof object]
                        return <div key={`investment-calculator-form-filed-${oldFiled.dataKey}`} className='grid grid-cols-2 gap-4'>
                            <div className='py-4'><Label >{INVESTMENT_DETAILED_CHARGES_FORM_FIELDS[indx]?.label}</Label></div>
                            <RenderFormComponents {...oldFiled} formik={formik} value={formik.values[field.dataKey as keyof object]} onChange={(e: any) => formik.setFieldValue(field.dataKey, e)} />
                        </div>
                    })
                }
            </div>
            {/* <div className='flex justify-center'><RoundedButton className='mx-auto w-[150px]'>Submit</RoundedButton></div> */}
            <div className='uppercase text-primary font-semibold border-primary border-b-2 border-l-0 border-r-0 border-t-0 '>Calculation result</div>
            <div>
                {
                    INVESTMENT_RESULT_FORM_FIELDS && INVESTMENT_RESULT_FORM_FIELDS.map((field: FormField, indx: number) => {
                        let oldFiled = { ...field };
                        delete oldFiled["label" as keyof object]
                        return <div key={`investment-result-form-filed-${oldFiled.dataKey}`} className='grid grid-cols-2 gap-4'>
                            <div className='py-4'><Label >{INVESTMENT_RESULT_FORM_FIELDS[indx]?.label}</Label></div>
                            <RenderFormComponents {...oldFiled} formik={formik} value={formik.values[field.dataKey as keyof object]} onChange={(e: any) => formik.setFieldValue(field.dataKey, e)} />
                        </div>
                    })
                }
            </div>
            <div className=' flex justify-center space-x-2'>
                <RoundedButton className='w-[150px]' onClick={() => navigate(-1)} variant={'outline'}>Back</RoundedButton> <RoundedButton onClick={() => navigate("/" + MAIN_ROUTES_CONST.cell + "/" + CELL_ROUTES.payment + "/" + id)} className='w-[150px]'>Pay Now</RoundedButton>
            </div>
        </div>
    )
}

export default CellOverview