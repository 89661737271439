import React from 'react'
import { Label } from 'src/shadcn/components/ui'
import { Switch } from 'src/shadcn/components/ui/switch'

type Props = {}

const PrivacySettings = (props: Props) => {
  return (
    <div>
      {/* <div className="flex items-center space-x-2">
        <Label htmlFor="airplane-mode">Airplane Mode</Label>
        <Switch id="airplane-mode" />
      </div> */}
    </div>
  )
}

export default PrivacySettings