import React, { useMemo } from 'react'
import { Link, useLocation, useNavigation } from 'react-router-dom'
import { APP_NAV_TABS, convertToCamelCaseNormal, NAV_TABS, USER_ROLES } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'src/shadcn/components/ui/tabs'

type Props = {}

const NavigationMenu = (props: Props) => {

    const { pathname } = useLocation()
    const { currentUser } = useAuth()

    const NAVIGATOIN_MENU_ITEMS = useMemo(() => {
        let routes: any = NAV_TABS
        if (currentUser?.role == USER_ROLES.INVESTOR) {
            routes = APP_NAV_TABS
            delete routes["new_enquiry_request"]
        }
        else if (currentUser?.role == USER_ROLES.OFF_TAKER) {
            routes = APP_NAV_TABS
            delete routes["investment_calculator"]
        }
        return routes
    }, [currentUser, APP_NAV_TABS, NAV_TABS, currentUser])

    return (
        <div>
            <Tabs value={pathname} className="mr-2">
                <TabsList>
                    {Object.entries(NAVIGATOIN_MENU_ITEMS).map(([key, value]) => {
                        return <TabsTrigger key={key} value={"/" + value as string} >{
                            <Link to={"/" + value as string}>{convertToCamelCaseNormal(key)}</Link>
                        }</TabsTrigger>
                    })}
                </TabsList>
            </Tabs>
        </div>
    )
}

export default NavigationMenu