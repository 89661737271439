import React from 'react'
import { FiFacebook } from "react-icons/fi";
import { FaLinkedinIn } from "react-icons/fa6";
import { LiaTelegram } from "react-icons/lia";
import { FaInstagram } from "react-icons/fa";


type Props = {}

const JoinSection = (props: Props) => {
    return (
        <div className='h-[400px] max-w-[80vw] mx-auto flex flex-col justify-center space-y-6 '>
            <div className='uppercase text-xl font-bold'>Join our community <br /> today!</div>
            <div>The SunContract Project is global. Join in on the conversation with members from across the globe.</div>
            <div className='grid grid-cols-4 gap-0'>
                <div className=' text-xl  h-[50px] w-[250px] border border-primary text-primary hover:bg-primary hover:text-white rounded-xl flex justify-center items-center space-x-2'>
                    <span><FiFacebook /></span>
                    <span className='uppercase'>Facebook</span>
                </div>
                <div className=' text-xl  h-[50px] w-[250px] border border-primary text-primary hover:bg-primary hover:text-white rounded-xl flex justify-center items-center space-x-2'>
                    <span><FaLinkedinIn /></span>
                    <span className='uppercase'>LinkedIn</span>
                </div>
                <div className=' text-xl  h-[50px] w-[250px] border border-primary text-primary hover:bg-primary hover:text-white rounded-xl flex justify-center items-center space-x-2'>
                    <span><LiaTelegram /></span>
                    <span className='uppercase'>Telegram</span>
                </div>
                <div className=' text-xl  h-[50px] w-[250px] border border-primary text-primary hover:bg-primary hover:text-white rounded-xl flex justify-center items-center space-x-2'>
                    <span><FaInstagram /></span>
                    <span className='uppercase'>Instagram</span>
                </div>
                
            </div>
        </div>
    )
}

export default JoinSection