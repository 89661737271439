import { cn, FormField } from 'src/helpers';
import { Label } from '../../ui';
import FormikDateField from '../DateInpute/FormikDateField';
import Dropdown from '../Dropdown';
import FormikMUltiFIleUpload from '../FileUploadInput/FormikMUltiFIleUpload';
import FormikSingleFileUpload from '../FileUploadInput/FormikSingleFileUpload';
import FormikInput from '../InputeFiled/FormikInput';
import FormikPasswordFiled from '../PasswordField/FormikPasswordFiled';
import TextareaFormFiled from '../TextareaFormFiled';

interface Props extends FormField {
    value?: any,
    onChange?: (e: any) => void,
    onBlur?: (e: any) => void
    formik?: any
}


const RenderFormComponents = ({ label, dataKey, componentType, componentProps, value, onChange, onBlur, formik }: Props) => {
    // console.log({ componentProps });

    return (
        <div>
            {
                componentType == "input" && componentProps?.type && ['email', 'phone', 'text', 'url'].includes(componentProps?.type) &&
                <FormikInput
                    formik={formik}
                    labelStyle={componentProps?.labelStyle}
                    label={label}
                    nameKey={dataKey}
                    placeholder={componentProps?.placeholder}
                    regex={componentProps?.regex}
                    prefix={componentProps?.inputPrefixIcon}
                    numberFormatted={componentProps?.numberFormatted}
                    sufix={componentProps?.inputSuffixIcon}
                    maxLength={componentProps?.maxLength}
                    value={value}
                    showCharacterLength={componentProps.showCharacterLength}
                    type={componentProps?.type}
                    required={componentProps?.required}
                    onchange={onChange}
                    onBlur={onBlur}
                    disabled={componentProps?.disabled}
                    readonly={componentProps?.readonly}
                    description={componentProps?.description}
                    options={componentProps?.options}
                />
            }
            {
                componentType == "input" && componentProps?.type && ['password'].includes(componentProps?.type) &&
                <FormikPasswordFiled
                    formik={formik}
                    label={label}
                    labelStyle={componentProps?.labelStyle}
                    nameKey={dataKey}
                    placeholder={componentProps?.placeholder}
                    value={value}
                    required={componentProps?.required}
                    onChange={onChange}
                    onBlur={onBlur}
                    disabled={componentProps?.disabled}
                />
            }
            {
                componentType == "file_picker" && componentProps?.fileSelectionType == 'multi' &&
                <FormikMUltiFIleUpload
                    formik={formik}
                    label={label}
                    labelStyle={componentProps?.labelStyle}
                    nameKey={dataKey}
                    value={value}
                    required={componentProps?.required}
                    onChange={onChange} />
            }
            {
                componentType == "file_picker" && !componentProps?.fileSelectionType &&
                <FormikSingleFileUpload
                    formik={formik}
                    label={label}
                    labelStyle={componentProps?.labelStyle}
                    nameKey={dataKey}
                    value={value}
                    required={componentProps?.required}
                    onchange={onChange}
                    placeholder={componentProps?.placeholder}
                    disabled={componentProps?.disabled || (componentProps?.conditionalDisabled ? formik.values[componentProps.conditionalDisabled.formKey] == componentProps.conditionalDisabled.expectedValue : false)} />
            }
            {
                componentType == "options" && <div>
                    <Label className={cn(' text-xs mb-2', componentProps?.labelStyle)}>{label}</Label>
                    <div className='mt-2'>
                        <Dropdown
                            options={componentProps?.options}
                            value={value || formik.values[dataKey] || ""}
                            onChange={onChange} /></div>
                    {formik.touched[dataKey] && formik.errors[dataKey] && (
                        <p className={cn("text-xs font-medium text-destructive")}>
                            {formik.errors[dataKey]}
                        </p>
                    )}
                </div>
            }
            {
                componentType == "date_picker" && <div>
                    <FormikDateField
                        formik={formik}
                        label={label}
                        labelStyle={componentProps?.labelStyle}
                        nameKey={dataKey}
                        value={value}
                        onchange={onChange}
                        placeholder={componentProps?.placeholder}
                        required={componentProps?.required}
                        disabled={componentProps?.disabled || (componentProps?.conditionalDisabled ? formik.values[componentProps.conditionalDisabled.formKey] == componentProps.conditionalDisabled.expectedValue : false)} />
                </div>
            }
            {
                componentType == "textarea" && <div>
                    <TextareaFormFiled
                        formik={formik}
                        label={label}
                        labelStyle={componentProps?.labelStyle}
                        nameKey={dataKey}
                        value={value}
                        onChange={onChange}
                        placeholder={componentProps?.placeholder}
                        required={componentProps?.required}
                        disabled={componentProps?.disabled || (componentProps?.conditionalDisabled ? formik.values[componentProps.conditionalDisabled.formKey] == componentProps.conditionalDisabled.expectedValue : false)} />
                </div>
            }

        </div>
    )
}

export default RenderFormComponents