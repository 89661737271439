import { AxiosResponse } from 'axios'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { catchAsync, SESSIONS, successTheme } from 'src/helpers'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import { setEmailForValidatOtp, setSession } from 'src/redux/reducers/tempDataSlice'
import { registrationRequestApi } from 'src/services/requests/Auth'
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { Checkbox } from 'src/shadcn/components/ui'
import { REGISTRATION_FORM_FIELDS, registration_initialValues, registrationFormValidationSchema } from './modules'

type Props = {
    isOpenDialog?: (e: any) => void
}

const Registration = ({ isOpenDialog }: Props) => {

    const [isLoading, setisLoading] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const formik = useFormik({
        initialValues: registration_initialValues,
        validationSchema: registrationFormValidationSchema,
        onSubmit: (values, { setSubmitting }) => {
            // Handle form submission here
            handleRegistrationForm()
        },
    });


    const handleRegistrationForm = catchAsync(
        async () => {
            setisLoading(true)
            return await registrationRequestApi(formik.values);
        },
        (result: AxiosResponse) => {
            if (result.status == 201) {
                dispatch(setEmailForValidatOtp(formik.values.email))
                dispatch(setSession(SESSIONS.REGISTRATION))
                toast.success("Registration successful,OTP sent on you email.", { style: successTheme })
                formik.resetForm()
                isOpenDialog && isOpenDialog(false)
                navigate("/" + MAIN_ROUTES_CONST.verify_otp)

            }
            console.log('Registration successful:', result);
            setisLoading(false)

        },
        () => {
            setisLoading(false)
        }
    );

    console.log({ error: formik.errors, values: formik.values })

    return (
        <div className='space-y-4'>
            <div className=' font-bold uppercase text-primary'>SignUp</div>
            <div className='text-sm'>Please enter your email address and your password.</div>
            <div className='space-y-2'>
                <form className='space-y-2' onSubmit={formik.handleSubmit}>
                    <div className='space-y-2'>
                        {
                            REGISTRATION_FORM_FIELDS.map((field: any) => {
                                return <RenderFormComponents key={`login-form-${field.dataKey}`} formik={formik} value={formik.values[field.dataKey as keyof object]} {...field} onChange={(e: any) => formik.setFieldValue(field.dataKey, e)} />
                            })
                        }
                    </div>
                    <div className='space-x-2 text-sm flex items-center py-2'><Checkbox checked={formik.values.is_accept} onCheckedChange={(e) => formik.setFieldValue("is_accept", e)} /><span className='text-xs'>I Accept <Link to={"#"} className='text-blue-600 underline underline-offset-1' >Terms and Conditions</Link> and <Link to={"#"} className='text-blue-600 underline underline-offset-1' >Privacy Policy</Link> </span> </div>
                    <div>{formik.errors.is_accept && formik.touched.is_accept && <div className='text-red-500 text-xs'>{formik.errors.is_accept}</div>}</div>
                    <div><RoundedButton isLoading={isLoading} disabled={!formik.values.is_accept || isLoading} type='submit' className='w-full'>Sign Up</RoundedButton></div>
                </form>
            </div>
        </div>
    )
}

export default Registration