import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { USER_ROLES } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import EnquiryForm1 from 'src/modules/EnquiryForms/EnquiryForm1'

type Props = {}

const NewEnquiryForm = (props: Props) => {

    const { currentUser } = useAuth()
    const navigate = useNavigate()

    // useEffect(() => {
    //     if (currentUser?.role != USER_ROLES.OFF_TAKER) {
    //         navigate(-1)
    //     }
    //     return () => { }
    // }, [])

    return (
        <div className='flex flex-col space-y-2 '>
            <div className='text-xl text-primary uppercase font-semibold'>Enquiry Request</div>
            <EnquiryForm1 />
        </div>
    )
}

export default NewEnquiryForm