
import DiscoversSection from 'src/modules/Welcome/DiscoversSection'
import FeaturesSection from 'src/modules/Welcome/FeaturesSection'
import FeaturesSection1 from 'src/modules/Welcome/FeaturesSection1'
import HeroSection from 'src/modules/Welcome/HeroSection'
import JoinSection from 'src/modules/Welcome/JoinSection'

type Props = {}

const WelcomePage = (props: Props) => {
  return (
    <div>
      <div className='relative'>
        <div className='' ><HeroSection /></div>
        <div className='-mt-11 z-50 absolute bottom-0n left-0 right-0'><FeaturesSection1 /></div>
      </div>
      <div className='mt-[150px] max-w-[80vw] mx-auto '>
        <FeaturesSection />
      </div>
      <DiscoversSection />
      <JoinSection />
    </div>
  )
}

export default WelcomePage