import { AxiosResponse } from "axios";
import { ICreatePaymentRequestBody, IUpdatePaymentRequestBody } from "./modules";
import * as API_URL from "src/services/consts/Payments"
import { axiosInstance } from "src/services/axiosSetup";


export function createPaymentRequestRequestApi(data: ICreatePaymentRequestBody): Promise<any> {
    return axiosInstance.post(`${API_URL.INITIATE_PAYMENT}`, { ...data }).then((d: AxiosResponse) => d.data);
}

export function updatePaymentStatusRequestRequestApi(data: IUpdatePaymentRequestBody): Promise<any> {
    return axiosInstance.put(`${API_URL.UPDATE_PAYMENT_STATUS}`, { ...data }).then((d: AxiosResponse) => d.data);
}