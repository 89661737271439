import axios from "axios";
import { UPLOAD_FILE } from "src/services/consts/common/index";

export const uploadFileToServer = async (file: File): Promise<string> => {
    const formData = new FormData();
    formData.append('file', file);
    const response = await axios.post(UPLOAD_FILE, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    return response.data.fileUrl;
};