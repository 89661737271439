import { AxiosResponse } from 'axios'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { catchAsync, FormField, successTheme } from 'src/helpers'
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { ENQUIRY_FORM_1_FIELDS, enquiryForm1Schema, IEnquiryForm1, initialEnquiryForm1 } from './modules'
import { createNewProjectEnquiryRequestRequestApi } from 'src/services/requests/Projects'

type Props = {}

const EnquiryForm1 = (props: Props) => {

    const [isLoading, setisLoading] = useState(false)
    const navigate = useNavigate()

    const formik = useFormik<IEnquiryForm1>({
        initialValues: initialEnquiryForm1,
        validationSchema: enquiryForm1Schema,
        onSubmit: (values, { setSubmitting }) => {
            // Handle form submission here
            handleEnquiryForm()
        },
    });


    const handleEnquiryForm = catchAsync(
        async () => {
            setisLoading(true)
            let postData: any = {};
            Object.entries(formik.values).forEach(([key, value]) => {
                let filed: FormField | undefined = ENQUIRY_FORM_1_FIELDS.find((d: FormField) => d.dataKey == key)
                if (filed && filed.componentProps?.numberFormatted) {
                    postData[key] = value && value.toString().replace(/[^A-z0-9.]+/g, "")
                }
                else postData[key] = value
            });
            return await createNewProjectEnquiryRequestRequestApi(postData);
        },
        (result: AxiosResponse) => {
            if (result.status == 201) {
                toast.success("RTequest sent successful", { style: successTheme })
                navigate(-1)
            }
            setisLoading(false)
        },
        () => {
            setisLoading(false)
        }
    );

    console.log({ error: formik.errors, values: formik.values })

    return (

        <div className='space-y-2 py-4'>
            <form className='space-y-2' onSubmit={formik.handleSubmit}>
                <div className='grid grid-cols-3 gap-4 space-y-2'>
                    {
                        ENQUIRY_FORM_1_FIELDS.map((field: FormField) => {
                            if (field.dataKey == "imagesAttachment") {
                                return <div className="col-span-3"> <RenderFormComponents key={`new-enquiry-form-${field.dataKey}`} formik={formik} value={formik.values[field.dataKey as keyof object]} {...field} onChange={(e: any) => formik.setFieldValue(field.dataKey, e)} /></div>
                            }
                            else return <div><RenderFormComponents key={`new-enquiry-form-${field.dataKey}`} formik={formik} value={formik.values[field.dataKey as keyof object]} {...field} onChange={(e: any) => formik.setFieldValue(field.dataKey, e)} /></div>
                        })
                    }
                </div>
                <div className='flex justify-center space-x-2'>
                    <RoundedButton isLoading={isLoading} onClick={() => navigate(-1)} variant={'outline'} type='button' className='w-[150px]'>Cancel</RoundedButton>
                    <RoundedButton isLoading={isLoading} type='submit' className='w-[150px]'>Submit</RoundedButton>
                </div>
            </form>
        </div>

    )
}

export default EnquiryForm1