import { IMG_GREEN_ENERGY_AGGREMENT, IMG_GREEN_ENERGY_BANNER, IMG_GREEN_ENERGY_TOKENIZED, IMG_GREEN_ENERGY_VIRTUAL_ROOFTOP } from 'src/helpers'
import { Button } from 'src/shadcn/components/ui'

type Props = {}

const GreenEnergyMain = (props: Props) => {
    return (
        <div>
            <div className='w-full h-[300px] overflow-hidden' style={{ backgroundImage: "url(" + IMG_GREEN_ENERGY_BANNER + ")", backgroundSize: "cover" }} >
                <div className='flex flex-col justify-center items-center h-[100%]'>
                    <div className='text-primary text-4xl ' >Now, Buy your own Green Energy through Virtual Solar Marketplace</div>
                    <div className='text-white text-xs ' >Staying in high rise buildings, on rent in urban area</div>
                </div>
            </div>
            <div className='max-w-[80vw] my-[50px] space-y-[50px] mx-auto'>

                <div className='grid grid-cols-2 gap-4 max-w-[900px] mx-auto '>
                    <div>
                        <img className='mx-auto' src={IMG_GREEN_ENERGY_AGGREMENT} style={{ width: "200px", height: "200px" }} />
                    </div>
                    <div className='flex justify-center items-center'>
                        <div className='space-y-4'>
                            <div>
                                <div className='uppercase font-bold text-xl'>Digitalized </div>
                                <div className='uppercase font-bold text-xl text-primary '>Power Purchase Agreement</div>
                            </div>
                            <div className='text-xs'>
                                The governance of each Power Purchase Agreements (PPA), in tun every project, gets programmed on the blockchain enabled project marketplace to have accurate Contract management, avoid human errors, delays in responses, third party intermediary risks. It brings transparency amongst all stakeholders!!!
                            </div>
                        </div>
                    </div>

                    <div className='flex justify-center items-center'>
                        <div className='space-y-4 text-right'>
                            <div >
                                <div className='uppercase font-bold text-xl'>Tokenized </div>
                                <div className='uppercase font-bold text-xl text-primary '>Solar Project</div>
                            </div>
                            <div className='text-xs'>
                                <p>Each project is tokenized to enable trading on the project marketplace. Investors can easily purchase tokens via a mobile app, which represent their share or ownership in the solar energy project.</p>
                                <br />
                                <p>Tokenization ensures a transparent and cost-efficient investment process by eliminating intermediaries and minimizing transaction expenses.</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <img className='mx-auto' src={IMG_GREEN_ENERGY_TOKENIZED} style={{ width: "200px", height: "200px" }} />
                    </div>


                    <div>
                        <img className='mx-auto' src={IMG_GREEN_ENERGY_VIRTUAL_ROOFTOP} style={{ width: "200px", height: "200px" }} />
                    </div>
                    <div className='flex justify-center items-center'>
                        <div className='space-y-4'>
                            <div>
                                <div className='uppercase font-bold text-xl'>Virtual </div>
                                <div className='uppercase font-bold text-xl text-primary '>Rooftop</div>
                            </div>
                            <div className='text-xs'>
                                Investment you made of every Rs. 1000, virtually allocated you a panel ownership or rooftop area ownership. You can track the energy generation, carbon impact, your repayment status on the each Panel you purchase.
                            </div>
                        </div>
                    </div>
                </div>

                <div className='w-max-[800px] mx-auto space-y-4'>
                    <div className='uppercase font-bold text-xl text-center'>Heading to  be given</div>
                    <div className='text-center text-xs'>
                        <p>Solar Project of kW to MW scale sizes, the Marketplace allows your virtual ownership, partly or fully, in the solar project installed on the remote and unknown farmers household.</p>
                        <br />
                        <p>This marketplace help connect farmers with the urban customers in sharing project plant ownership and in turn, the green energy generated. As platform handles all the complexities in real-time, it’s billing and settlement becomes very swift process for the distribution company.</p>
                    </div>
                    <div className='flex justify-center '><Button className='rounded-full' >Contact Us</Button></div>
                </div>
            </div>
        </div>
    )
}

export default GreenEnergyMain