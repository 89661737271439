import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { convertToCamelCase, IMG_FAQ_BANNER } from 'src/helpers'
import ProjectLeftDetails from 'src/modules/Cell/ProjectLeftDetails'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import { fetchProjectDetailsAsync, getProjectDetailsRootState } from 'src/redux/reducers/projectDetailsSlice'
import { RootState } from 'src/redux/store'

type Props = {}

const CellLayout = (props: Props) => {

  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const { id } = useParams()
  const navigate = useNavigate()
  const [path, setpath] = useState("")
  const { project_details } = useSelector((state: RootState) => getProjectDetailsRootState(state))

  useEffect(() => {
    if (id) {
      handleFetchProductDetails()
    }
    else navigate("/" + MAIN_ROUTES_CONST.marketplace)
    return () => { }
  }, [])

  const handleFetchProductDetails = async () => {
    id && dispatch(fetchProjectDetailsAsync(id))
  }


  useEffect(() => {
    let paths = pathname.split("/")
    setpath(convertToCamelCase(paths[2]) + " / " + paths[3])
    return () => { }
  }, [pathname])


  return (
    <div>
      <div className='w-full h-[150px] overflow-hidden' style={{ backgroundImage: "url(" + IMG_FAQ_BANNER + ")", backgroundSize: "cover" }} >
        <div className='flex flex-col justify-center mx-[50px] h-[100%]'>
          <div className='text-white text-4xl font-bold uppercase' >{project_details?.projectName }</div>
          <div className='text-white text-xs '>{path}</div>
        </div>
      </div>

      <div className='max-w-[80vw] mx-auto'>
        <div className='flex '>
          <div className='md:w-1/3 xl:w-2/6 sticky top-[3em] h-[500px] p-2 '>
            <ProjectLeftDetails />
          </div>
          <div className='md:w-2/3 xl:w-4/6 w-full h-full p-2'>
            <Outlet />
          </div>
        </div>
      </div>
      {/* <div className='max-w-[80vw] mx-auto'> <Outlet /></div> */}
    </div>
  )
}

export default CellLayout