import React from 'react'
import { IMG_ABOUT_US_BANNER } from 'src/helpers'
import { Carousel, CarouselContent, CarouselItem } from 'src/shadcn/components/ui/carousel'
import { CUROUSAL_DATA } from './modules'
import { MoveUpRight } from 'lucide-react'

type Props = {}



const AboutMain = (props: Props) => {
    return (
        <div>
            <div className='w-full h-[300px] overflow-hidden' style={{ backgroundImage: "url(" + IMG_ABOUT_US_BANNER + ")", backgroundSize: "cover" }} >
                <div className='flex flex-col justify-center items-center h-[100%]'>
                    <div className='text-primary text-4xl ' >About Us</div>
                    <div className='text-white text-xs ' >Welcome to ORNAGE CURRENT</div>
                </div>
            </div>
            <div className='max-w-[80vw] my-[50px] space-y-[50px] mx-auto'>
                <h1 className="text-xl font-bold text-center text-primary mb-4">Welcome to Orange Current</h1>

                <p className="text-sm text-center mb-8">
                    Orange Current Technology strongly believe that new technologies such as blockchain,
                    machine learning, artificial intelligence, robotics will become the basis for creating
                    new solutions, services, markets across entire energy supply chain.
                </p>

                <h2 className="text-xl font-bold text-center mb-4 uppercase" >Products on Blockchain</h2>
                <div>
                    <Carousel
                        opts={{ align: "start", }}
                        className="w-full"
                    >
                        <CarouselContent>
                            {CUROUSAL_DATA.map(({ image, header, sub_header, content }, index) => (
                                <CarouselItem key={index} className="md:basis-1/2 lg:basis-1/3">
                                    <div className='relative h-[250px] rounded-2xl overflow-hidden' style={{ backgroundImage: "url(" + image + ")", backgroundSize: "cover" }}>
                                        <div className='absolute left-0 right-0 bottom-0  p-6 text-white bg-slate-700 border border-t-primary border-t-4 border-l-0 border-r-0 border-b-0 space-y-2'>
                                            <div className='flex justify-between'><div className='text-lg'>{header}</div> <MoveUpRight className='text-primary' /></div>
                                            <div className='text-xs font-light w-80'>
                                                <div ><i>{sub_header}</i> </div>
                                                <div><i>{content}</i> </div>
                                            </div>
                                        </div>
                                    </div>
                                </CarouselItem>
                            ))}
                        </CarouselContent>
                        {/* <CarouselPrevious />
                    <CarouselNext /> */}
                    </Carousel>
                </div>

                <div className="mt-16">
                    <h2 className="text-xl font-bold text-center mb-4 uppercase">Mission and Vision</h2>

                    <p className="text-sm text-center mb-8">
                        We have recognized the signs of the times. We simplify and accelerate sustainable investments in solar energy. Thus, we are already making an active
                        contribution to the future project of global energy transition. We will maintain and further expand our market position for this purpose.
                    </p>

                    <h2 className="text-xl font-bold text-center mb-4 uppercase">Transparency and Openness</h2>

                    <p className="text-sm text-center">
                        As intermediaries, we are aware of our responsibility and support the cooperation of all partners and sellers of PV projects with a smooth process. All
                        processes are secure, clearly defined, and transparent for all parties involved.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default AboutMain