import { AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { catchAsync } from 'src/helpers'
import ProjectLeftDetails from 'src/modules/Cell/ProjectLeftDetails'
import ProjectRightDetail from 'src/modules/Cell/ProjectRightDetail'
import { IProject } from 'src/modules/Marketplace/modules'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import { resetInvestment } from 'src/redux/reducers/projectDetailsSlice'
import { getProjectDetailsByIDRequestApi } from 'src/services/requests/Projects'

type Props = {}

const ProductDetails = (props: Props) => {

    const { id } = useParams()
    const dispatch = useDispatch()

    useEffect(() => {
      dispatch(resetInvestment())
      return () => {}
    }, [])
    

    return (
        <>
            {
                id && <div className='flex '>
                    <div className='md:w-1/3 xl:w-2/6 sticky top-[3em] h-[500px] p-2 '>
                        <ProjectLeftDetails />
                    </div>
                    <div className='md:w-2/3 xl:w-4/6 w-full h-full p-2'>
                        <ProjectRightDetail />
                    </div>
                </div>
            }
        </>
    )
}

export default ProductDetails