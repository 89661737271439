import React from 'react'
import { InputOTP, InputOTPGroup, InputOTPSeparator, InputOTPSlot } from '../ui'

type Props = {
    onChange: (e: any) => void,
    disabled?:boolean
}

const OtpInput = ({onChange,disabled}: Props) => {
    return (
        <InputOTP disabled={disabled || false} maxLength={6} onChange={onChange} >
            <InputOTPGroup>
                <InputOTPSlot index={0} />
                <InputOTPSlot index={1} />
                <InputOTPSlot index={2} />
                <InputOTPSlot index={3} />
                <InputOTPSlot index={4} />
                <InputOTPSlot index={5} />
            </InputOTPGroup>
        </InputOTP>
    )
}

export default OtpInput