import React, { useState } from 'react'
import { Select, SelectContent, SelectGroup, SelectItem, SelectSeparator, SelectTrigger, SelectValue } from '../ui/select'
import { Cross, X } from 'lucide-react'
import { Separator } from '@radix-ui/react-select'
import { optionTypes } from 'src/helpers'


type Props = {
    options?: optionTypes[],
    placeholder?: string,
    value: string,
    onChange?: (e: any) => void,
    clearable?: boolean
}

const Dropdown = ({ options, onChange, value, clearable =false }: Props) => {

    return (
        <Select value={value} onValueChange={(e: any) => {
            let value = e == "clear" ? "" : e
            onChange && onChange(value)
        }}  >
            <SelectTrigger clearable={clearable} onClear={() => onChange && onChange("")} className="w-[180px]">
                <div className=' flex justify-between items-center w-full'>
                    <div>{value || <div className='text-gray-400' >Select</div>} </div>
                </div>
            </SelectTrigger>
            <SelectContent>
                <SelectGroup>
                    {options?.map(({ label, value }: optionTypes) => {
                        return <SelectItem value={value}>{label}</SelectItem>
                    })}
                </SelectGroup>
            </SelectContent>
        </Select>
    )
}
export default Dropdown