import { ColumnDef } from "@tanstack/react-table";
import { Task } from "../data/schema";
import { DataTableColumnHeader } from "./data-table-column-header";
import { DataTableRowActions } from "./data-table-row-actions";
import { Button } from "src/shadcn/components/ui";
import { X } from "lucide-react";
import { checkDecimal } from "src/helpers";


export const columns: ColumnDef<Task>[] = [

  {
    accessorKey: 'projectId',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Project Id (#)" />
    ),
  },

  {
    accessorKey: 'projectType',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Project Type" />
    ),
  },
  // {
  //   accessorKey: 'systemType',
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="System Type" />
  //   ),
  // },
  {
    accessorKey: 'projectSize',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Project Size" />
    ),
    cell({ row: { original: { projectSize} } }) {
      return projectSize ? checkDecimal(projectSize, true) : null
    },
  },
  {
    accessorKey: 'totalProjectPrice',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Price (₹)" />
    ),
    cell({ row: { original: { totalProjectPrice} } }) {
      return totalProjectPrice ? checkDecimal(totalProjectPrice, true) : null
    },
  },
  // {
  //   accessorKey: 'pricePerKwh',
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Price (₹) / Kwh" />
  //   ),
  // },
  {
    accessorKey: 'investedAmount',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Invested Amount (₹)" />
    ),
    cell({ row: { original: { investedAmount} } }) {
      return investedAmount ? checkDecimal(investedAmount, true) : null
    },
  },
  {
    id: 'eta_returns',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="ETA Returns (₹)" />
    ),
    cell({ row: { original: { returnPerYear, investedAmount, roi } } }) {
      let cal =Number(returnPerYear) - Number(investedAmount)
      return <div>{checkDecimal(Number(cal).toString(),true)} (9%) </div>
    },
  },
  {
    id: 'actual_returns',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Actual Returns (₹)" />
    ),
    cell({ row: { original: { returnPerYear, investedAmount, roi } } }) {
      let percentage = ((Number(returnPerYear) - Number(investedAmount)) / Number(returnPerYear)) * 100;
      percentage = Number(percentage.toFixed(2))
      return <div className="flex space-x-1">
        <div>{returnPerYear ? checkDecimal(returnPerYear,true) : "-"}</div>
        <div>
          {percentage > 9 ? <span className="text-green-600 font-semibold mx-2">(+{percentage} %)</span> : <span className="text-red-600 font-semibold  mx-2">(-{percentage} %)</span>}
        </div>
      </div>
    },
  },
  {
    accessorKey: 'returnPerYear',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Return Per Year (₹)" />
    ),
    cell({ row: { original: { returnPerYear} } }) {
      return returnPerYear ? checkDecimal(returnPerYear, true) : null
    },
  },
  {
    id: 'actions',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Actions" />
    ),
    cell(props) {
      return <Button size={'sm'}>Show Transaction</Button>
    },
  },

];
