import { TrendingUp } from 'lucide-react'
import React from 'react'
import { ICON_SEND } from 'src/helpers'
import { Button } from 'src/shadcn/components/ui'
import InvestmentListItem from './InvestmentListItem'

type Props = {}

const InverstmentSummary = (props: Props) => {
    return (
        <div className='space-y-2'>
            <div className='flex flex-col items-center space-y-2'>
                <div className='text-sm'>Total Balance</div>
                <div className='font-bold text-2xl '>₹ 0</div>
                <div className='space-x-2 flex text-sm items-center' ><span className='h-[25px] w-[25px] rounded-full bg-gray-100 flex justify-center items-center text-primary'><TrendingUp size={16} /></span> <span> 00.00%</span></div>
            </div>
            <div className='flex space-x-2'>
                <Button disabled className='space-x-2 flex-1'> <span>Send</span><img src={ICON_SEND} alt='send money' /></Button>
                <Button disabled className='space-x-2 flex-1'> <span>Receive</span><img src={ICON_SEND} alt='send money' /></Button>
            </div>
            <div className='text-sm text-primary my-2 font-bold'>Recent Transactions</div>
            <div className='flex flex-col space-y-2'>
                <InvestmentListItem />
                {/* <InvestmentListItem />
                <InvestmentListItem />
                <InvestmentListItem />
                <InvestmentListItem />
                <InvestmentListItem /> */}
            </div>
            <div className='text-center text-sm cursor-pointer hover:text-primary'>View More</div>
        </div>
    )
}

export default InverstmentSummary