import axios from 'axios';
import { toast } from 'sonner';
import { BASE_URL } from './consts/api_const';
import { getAuth } from 'src/modules/Auth/Core';

const createAxiosInstance = (withoutAuth: boolean) => {
    const instance = axios.create({
        baseURL: BASE_URL,
        timeout: 5000,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    instance.interceptors.request.use(
        (config) => {
            const token = getAuth();
            console.log({ token });
            if (withoutAuth && token && token.api_token) {
                config.headers['Authorization'] = `Bearer ${token.api_token}`;
            }
            return config;
        },
        (error) => Promise.reject(error)
    );

    // instance.interceptors.response.use(
    //     (response) => response,
    //     (error) => {
    //         handleError(error);
    //         return Promise.reject(error);
    //     }
    // );

    return instance;
};

const axiosInstance = createAxiosInstance(true);
const axiosInstanceWithoutAuth = createAxiosInstance(false);


function handleError(error: any) {
    if (axios.isAxiosError(error)) {
        const { response } = error;
        if (response) {
            const { data, status } = response;
            if (status === 401) {
                toast.error('Unauthorized');
            }
            else {
                toast.error(data.message);
            }
        } else {
            toast.error('Network error');
        }
    } else {
        toast.error('Unexpected error');
    }
}


export { axiosInstance,axiosInstanceWithoutAuth };