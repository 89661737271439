
import { FormField, NUMBER_REGEX } from 'src/helpers';
import * as Yup from 'yup';

export interface IEnquiryForm2 {
    project_id?:string,
    contactPersonName: string;
    contactPersonNumber: string;
    enquiry: string;
    emailAddress: string;
    unitConsumptionPerDay: number | undefined;
    projectDescription: string;
}


export const initialEnquiryForm2: IEnquiryForm2 = {
    contactPersonName: '',
    contactPersonNumber: '',
    enquiry: '',
    emailAddress: '',
    unitConsumptionPerDay: undefined,
    projectDescription: ''
};

export const enquiryForm2Schema = Yup.object().shape({
    contactPersonName: Yup.string().required('Contact person name is required'),
    contactPersonNumber: Yup.string().length(10).required('Contact person number is required'),
    enquiry: Yup.string().required('Enquiry is required'),
    emailAddress: Yup.string().email('Invalid email address').required('Email address is required'),
    unitConsumptionPerDay: Yup.number().required(" consumption unit per day is required"),
    projectDescription: Yup.string().required('Project description is required'),
});


export const ENQUIRY_FORM_2_FIELDS: FormField[] = [
    {
        label: 'Contact Person Name',
        dataKey: 'contactPersonName',
        componentType: 'input',
        componentProps: {
            type: 'text',
            placeholder: 'Enter contact person name',
        },
    },
    {
        label: 'Contact Person Number',
        dataKey: 'contactPersonNumber',
        componentType: 'input',
        componentProps: {
            type: 'text',
            regex: NUMBER_REGEX,
            maxLength: 10,
            placeholder: 'Enter contact person number',
        },
    },
    {
        label: 'Enquiry',
        dataKey: 'enquiry',
        componentType: 'options',
        componentProps: {
            options: [
                { label: 'Enquiry 1', value: 'enquiry1' },
                { label: 'Enquiry 2', value: 'enquiry2' },
                // Add more options as needed
            ],
        },
    },
    {
        label: 'Email Address',
        dataKey: 'emailAddress',
        componentType: 'input',
        componentProps: {
            type: 'email',
            placeholder: 'Enter email address',
        },
    },
    {
        label: 'Unit Consumption Per Day',
        dataKey: 'unitConsumptionPerDay',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: NUMBER_REGEX,
            placeholder: 'Units',
        },
    },
    {
        label: 'Project Description',
        dataKey: 'projectDescription',
        componentType: 'textarea',
        componentProps: {
            placeholder: 'Enter project description',
        },
    },
];