import React from 'react'
import { OFFTACKER_ANALYSIS_DATA } from './modules'
import AnalysisTiles from 'src/modules/Dashboard/AnalysisTiles'
import { ChartDisplay } from 'src/shadcn/components/custom/ChartDispaly'
import InvestmentAnalysis from 'src/modules/Dashboard/InvestmentAnalysis'
import OffTakerAnalysis from 'src/modules/Dashboard/Offtacker/OffTakerAnalysis'
import { DataTable } from 'src/modules/Dashboard/OffTackerHistory/Table/components/data-table'
import { columns } from 'src/modules/Dashboard/OffTackerHistory/Table/components/columns'

type Props = {}



const OffTakerDashboard = (props: Props) => {
    return (
        <div className='space-y-2 py-4'>
            <div className='font-semibold text-primary uppercase'>Dashboard</div>
            <div>
                <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
                    {
                        OFFTACKER_ANALYSIS_DATA.map((d: any) => {
                            return <AnalysisTiles {...d} />
                        })
                    }
                </div>
            </div>
            <div className='font-semibold text-primary uppercase'>Electricity consumption and generation</div>
            <div className='grid grid-cols-1'>
                <ChartDisplay >
                    <OffTakerAnalysis  />
                </ChartDisplay>
            </div>
            <div className='font-semibold text-primary uppercase'>EMI HISTORY</div>
            <DataTable data={[]} columns={columns} />
        </div>
    )
}

export default OffTakerDashboard