import { useFormik } from 'formik'
import React, { useState } from 'react'
import { INVESTMENT_CALCULATOR_FORM_FIELDS, INVESTMENT_RESULT_FORM_FIELDS } from './modules'
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents'
import { Label } from 'src/shadcn/components/ui'
import { catchAsync, FormField } from 'src/helpers'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { getInvestmentCalculationsRequestApi } from 'src/services/requests/investments'
import { InvestmentReturnResponse } from 'src/services/requests/investments/modules'

type Props = {}

const InvestmentCalculatorForm = (props: Props) => {


    // const [resultData, setresultData] = useState<{
    //     annualRoi: number,
    //     expectedReturnPerYear: number,
    //     investmentGainPerYear: number,
    //     totalReturn: number,
    //     yearTillReturn: number
    // }>({
    //     annualRoi: 0,
    //     expectedReturnPerYear: 0,
    //     investmentGainPerYear: 0,
    //     totalReturn: 0,
    //     yearTillReturn: 0
    // })

    const formikResult = useFormik({
        initialValues:
        {
            annualRoi: "",
            expectedReturnPerYear: "",
            investmentGainPerYear: "",
            totalReturn: "",
            yearTillReturn: "",
        },
        onSubmit(values, formikHelpers) {

        },
    })

    const formik = useFormik({
        initialValues: {
            investmentAmount: ""
        },
        onSubmit(values, formikHelpers) {
            handleGetCalculation()
        },
    })

    const [isLoading, setisLoading] = useState(false)



    const handleGetCalculation = catchAsync(async () => {
        setisLoading(true)
        return formik.values.investmentAmount && await getInvestmentCalculationsRequestApi({ investment_amount: formik.values.investmentAmount && formik.values.investmentAmount.toString().replace(/[^A-z0-9.]+/g, "")  })
    }, (result: InvestmentReturnResponse) => {
        console.log({ result });

        if (result.code == 200) {
            const { estimated_return_per_year, investment_amount, expected_irr, total_return_after72_months, total_time_period } = result.data
            const expectedReturnPerYear = Number(investment_amount) * (Number(expected_irr) / 100)
            const yearTillReturn = Number(total_time_period) / 12
            // investmentResultFormik.setFieldValue("totalInterestReturn", expectedReturnPerYear * yearTillReturn)
            // investmentResultFormik.setFieldValue("investmentGain", estimated_return_per_year)
            // investmentResultFormik.setFieldValue("expectedReturnPerYear", expectedReturnPerYear)
            // investmentResultFormik.setFieldValue("finalReturn", Number(investment_amount) + Number(expectedReturnPerYear * yearTillReturn))
            // investmentResultFormik.setFieldValue("yearTillReturn", yearTillReturn)

            // setresultData({
            //     annualRoi: expected_irr,
            //     expectedReturnPerYear: estimated_return_per_year,
            //     investmentGainPerYear: estimated_return_per_year,
            //     totalReturn: total_return_after72_months,
            //     yearTillReturn: yearTillReturn
            // })

            formikResult.setFieldValue("annualRoi", expected_irr)
            formikResult.setFieldValue("expectedReturnPerYear", estimated_return_per_year)
            formikResult.setFieldValue("investmentGainPerYear", estimated_return_per_year)
            formikResult.setFieldValue("totalReturn", total_return_after72_months)
            formikResult.setFieldValue("yearTillReturn", yearTillReturn)


            setisLoading(false)
        }
    }, () => { setisLoading(false) })

    return (

        <div className='space-y-4 p-4  '>
            <div className='uppercase text-primary font-semibold border-primary border-b-2 border-l-0 border-r-0 border-t-0 '>investment calculator</div>
            <div className='flex space-x-2'>
                <div className='md:3/4 lg:w-4/6 p-4 space-y-2 bg-gray-100 '>
                    <form onSubmit={formik.handleSubmit}>
                        {
                            INVESTMENT_CALCULATOR_FORM_FIELDS ? INVESTMENT_CALCULATOR_FORM_FIELDS.map((field: FormField, indx: number) => {
                                let oldFiled = { ...field };
                                delete oldFiled["label" as keyof object]
                                return <div key={`investment-calculator-form-filed-${oldFiled.dataKey}`} className='grid grid-cols-2 gap-4'>
                                    <div className='py-4'><Label >{INVESTMENT_CALCULATOR_FORM_FIELDS[indx]?.label}</Label></div>
                                    <RenderFormComponents {...oldFiled} formik={formik} value={formik.values[field.dataKey as keyof object]} onChange={(e: any) => formik.setFieldValue(field.dataKey, e)} />
                                </div>
                            }) : null
                        }
                        <div className='flex justify-end py-4'>
                            <RoundedButton className='w-[150px]'>Submit</RoundedButton>
                        </div>
                    </form>
                </div>

                <div className='md:1/4 lg:w-2/6 p-4 border bg-orange-50 '>
                    {
                        INVESTMENT_RESULT_FORM_FIELDS ? INVESTMENT_RESULT_FORM_FIELDS.map((field: FormField, indx: number) => {
                            let oldFiled = { ...field };
                            return <div key={`investment-calculator-result-form-filed-${oldFiled.dataKey}`} className=''>
                                <RenderFormComponents {...oldFiled} formik={formikResult} value={formikResult.values[field.dataKey as keyof object]} />
                            </div>
                        }) : null
                    }
                </div>
            </div>
        </div>
    )
}

export default InvestmentCalculatorForm