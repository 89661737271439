import { IndianRupee, Wallet } from 'lucide-react'
import React from 'react'

type Props = {}

const WalletSummary = (props: Props) => {
    return (
        <div>
            <div className='flex  justify-between items-center'>
                <div className=' flex justify-center items-center space-x-2'>
                    <Wallet size={24} />
                    <div className="">
                        <div className='text-sm text-gray-400'>Wallet</div>
                        <div className='flex items-center text-xl font-bold '>
                            <span><IndianRupee size={20} className='text-primary' /></span>
                            <span>2,22,254.02</span>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='text-sm text-gray-400'>Bakance</div>
                    <div className='flex items-center text-xl font-bold '>
                        <span><IndianRupee size={20} className='text-primary' /></span>
                        <span>0</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WalletSummary