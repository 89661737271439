import React, { useEffect, useState } from 'react'
import { TrendingUp } from "lucide-react"
import { CartesianGrid, Line, LineChart, XAxis, YAxis } from "recharts"
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
    TabsList,
    TabsTrigger,
} from "src/shadcn/components/ui"
import { Tabs } from '@radix-ui/react-tabs'
import { catchAsync, convertToCamelCase, stringifyObjectToRequestQuery } from 'src/helpers'
import { IInventoryOverviewResponseBody } from 'src/services/requests/dashboard/modules'
import { getInvestorOverviewRequestApi } from 'src/services/requests/dashboard'
import { useAuth } from '../Auth/Core'
import dayjs from 'dayjs'

const chartData = [
    { month: "January", investment: 186, returns: 0 },
    { month: "February", investment: 305, returns: 0 },
    { month: "March", investment: 237, returns: 0 },
    { month: "April", investment: 73, returns: 0 },
    { month: "May", investment: 209, returns: 0 },
    { month: "June", investment: 214, returns: 0 },
]

const chartConfig = {
    investment: {
        label: "Investment",
        color: "hsl(var(--chart-1))",
    },
    returns: {
        label: "Returns",
        color: "hsl(var(--chart-2))",
    },

} satisfies ChartConfig

type Props = {}


const monthMap: any = {
    "jan": "January",
    "feb": "February",
    "mar": "March",
    "april": "April",
    "may": "May",
    "jun": "June",
    "jul": "July",
    "aug": "August",
    "sep": "September",
    "oct": "October",
    "nov": "November",
    "dec": "December"
};



const InvestmentAnalysis = (props: Props) => {


    const { currentUser } = useAuth()
    const CHART_TYPE = {
        MONTHLY: "monthly",
        WEEKLY: "weekly",
        YEARLY: "yearly"
    }

    const [chartData, setchartData] = useState<any>()
    const [isLoading, setisLoading] = useState(false)

    useEffect(() => {
        handleFetchChartData(CHART_TYPE.MONTHLY, stringifyObjectToRequestQuery({
            userId: currentUser?.id
        }))
        return () => { }
    }, [])

    const handleFetchChartData = catchAsync(async (chartType: string, query: string) => {
        // console.log({ chartType, query });
        setisLoading(true)
        return await getInvestorOverviewRequestApi(chartType, query)
    }, (result: any, params: any) => {
        // console.log({ params: params?.args });
        currentUser && result && setchartData(Object.keys(result.data).map((key: any) => ({
            "month": key,
            "investment": result.data[key],
            "returns":Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000
        })))
        setisLoading(false)
    }, () => {
        setisLoading(false)
    })

    console.log({ chartData });

    return (

        <Card className='shadow-none'>
            <CardHeader>
                <div className='flex justify-between items-center  mb-2'>
                    <div className=''>
                        <CardTitle>Investment overview</CardTitle>
                    </div>
                    <div className='flex justify-end'>
                        <div>
                            <Tabs defaultValue="monthly" >
                                <TabsList>
                                    <TabsTrigger className='text-xs' onClick={() => {
                                        const startDate = dayjs().day(1).format("YYYY-MM-DD")
                                        const endDate = dayjs().day(7).format("YYYY-MM-DD")
                                        const query = stringifyObjectToRequestQuery({
                                            startDate: startDate,
                                            endDate: endDate,
                                            userId: currentUser?.id
                                        })
                                        // console.log({ query });

                                        handleFetchChartData(CHART_TYPE.WEEKLY, query)
                                    }} value="weekly">Weekly</TabsTrigger>
                                    <TabsTrigger className='text-xs' onClick={() => {
                                        const query = stringifyObjectToRequestQuery({
                                            userId: currentUser?.id
                                        })
                                        // console.log({ query });
                                        handleFetchChartData(CHART_TYPE.MONTHLY, query)
                                    }} value="monthly">Monthly</TabsTrigger>
                                    <TabsTrigger className='text-xs' onClick={() => {
                                        const startYear = dayjs().format("YYYY")
                                        const endYear = dayjs().add(7, 'year').format("YYYY")
                                        const query = stringifyObjectToRequestQuery({
                                            startYear: startYear,
                                            endYear: endYear,
                                            userId: currentUser?.id
                                        })
                                        // console.log({ query });
                                        handleFetchChartData(CHART_TYPE.YEARLY, query)
                                    }} value="yearly">Yearly</TabsTrigger>
                                </TabsList>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </CardHeader>

            <CardContent>
                <div className='h-[300px]'>
                    <ChartContainer config={chartConfig} className='max-h-full w-full'>
                        <LineChart
                            accessibilityLayer
                            data={chartData}
                            margin={{
                                left: 0,
                                right: 0,
                            }}
                        >
                            <CartesianGrid vertical={false} />
                            <XAxis
                                dataKey="month"
                                tickLine={false}
                                axisLine={false}
                                tickMargin={8}
                            // tickFormatter={(value) => value.slice(0, 3)}
                            />
                            <YAxis
                                axisLine={false}
                                tickMargin={8}
                                tickLine={false}
                            />
                            <ChartTooltip
                                cursor={false}
                                content={<ChartTooltipContent hideLabel />}
                            />
                            <Line
                                dataKey="investment"
                                type="monotone"
                                stroke="var(--color-investment)"
                                strokeWidth={2}
                                dot={true}
                            />
                            <Line
                                dataKey="returns"
                                type="monotone"
                                stroke="var(--color-returns)"
                                strokeWidth={2}
                                dot={true}
                            />
                        </LineChart>
                    </ChartContainer>
                </div>
                {
                    isLoading ? <div className='absolute top-0 left-0 right-0 bottom-0 w-100 h-100 flex items-center justify-center'>
                        <div className='  p-3 bg-gray-800 text-white rounded-xl w-[100px] text-sm text-center'>Loading....</div>
                    </div> : null
                }
            </CardContent>

            <CardFooter className="flex-col items-start gap-2 text-sm">

            </CardFooter>
        </Card>

    )
}

export default InvestmentAnalysis