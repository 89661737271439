import React from 'react'
import { ICON_PROFIT_AS_BUYER, ICON_PROFIT_AS_SALER, IMG_P2P_ENERGY_TRADING } from 'src/helpers'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'

type Props = {}

const P2pEnergyTradingMain = (props: Props) => {
    return (
        <div>
            <div>
                <div className='w-full h-[300px] overflow-hidden' style={{ backgroundImage: "url(" + IMG_P2P_ENERGY_TRADING + ")", backgroundSize: "cover" }} >
                    <div className='flex flex-col justify-center items-center h-[100%] space-y-2 '>
                        <div className='text-primary text-4xl ' >P2P Energy Trading</div>
                        <div className='text-white text-xs ' >The Electricity Marketplace offers a wide variety of features that make it an ideal place to buy and sell renewable energy.</div>
                    </div>
                </div>
                <div className='max-w-[80vw] my-[50px] space-y-[50px] mx-auto'>
                    <div className='mx-auto w-[80%] space-y-4'>
                        <div className='text-center text-primary'>The <span className='font-bold'>Electricity Marketplace</span></div>
                        <div className='text-center'>offers a wide variety of features that make it an ideal place to buy and sell renewable energy. The <span className='font-semibold'>Electricity platform</span> today has more than <span className='font-semibold'> 500+ registered customers</span> within India.</div>
                        <div className='text-center'>Customers can buy and sell energy directly with one another in compliance with the existing legislation.</div>
                    </div>
                    <div className='mx-auto w-[80%]'>
                        <div className='grid grid-cols-2 gap-4'>
                            <div className='border border-2 border-primary rounded-xl flex justify-center items-center p-4 space-x-4 '>
                                <div>
                                    <img src={ICON_PROFIT_AS_SALER} style={{width:"40px",height:'40px'}} />
                                </div>
                                <div className='flex flex-col'>
                                        <div>Earn</div>
                                        <div className='text-2xl font-bold text-primary'>25%</div>
                                        <div>more as Sales</div>
                                </div>
                            </div>
                            <div className='border border-2 border-primary rounded-xl flex justify-center items-center p-4 space-x-4'>
                                <div>
                                <img src={ICON_PROFIT_AS_BUYER} style={{width:"40px",height:'40px'}} />
                                </div>
                                <div className='flex flex-col'>
                                        <div>Save</div>
                                        <div className='text-2xl font-bold text-primary'>25%</div>
                                        <div>more as Buyer</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mx-auto w-[80%] space-y-4'>
                        <div className='text-center text-xl text-primary uppercase font-bold'>key Features</div>
                        <div className='text-center'>offers a solution on all type of PV Solar projects including Sell, buy, acquire or Market your Solar PV Projects. Also own your house rooftop solar project with better financing options</div>
                    </div>
                    <div className='flex justify-center items-center space-x-2'>
                        <RoundedButton>Choose Your Supplier</RoundedButton>
                        <RoundedButton>Go To Portal</RoundedButton>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default P2pEnergyTradingMain