import { ImageOff, LockKeyhole, TvMinimalPlay, Youtube } from 'lucide-react'
import React, { useMemo, useState } from 'react'
import { cn, ICON_PROJECT_SIZE, ICON_PROJECT_TYPE, ICON_ROOF_TOP, PROJECT_IMAGE_URL, USER_ROLES } from 'src/helpers'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { Carousel, CarouselContent, CarouselItem } from 'src/shadcn/components/ui'
import { IProject } from '../Marketplace/modules'
import { useAuth } from '../Auth/Core'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/store'
import { getProjectDetailsRootState } from 'src/redux/reducers/projectDetailsSlice'
import { useNavigate, useParams } from 'react-router-dom'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import { CELL_ROUTES } from 'src/navigations/CellRoutes'
import { initialProjectData, initialProjectFinanceData, initialTechnicalData, PROJECT_FORM_FIELDS } from './module'
import RenderData from './RenderData'
import ViewProjectDetails from './ProjectDetails/ViewProjectDetails'
import DocumentViwer from 'src/shadcn/components/custom/DocumentViwer'

type Props = {

}



const ProjectRightDetail = ({ }: Props) => {

    const navigate = useNavigate()
    const { id } = useParams()
    const { currentUser } = useAuth()
    const { project_details: projectDetails } = useSelector((state: RootState) => getProjectDetailsRootState(state))

    const [selectedImageIndex, setSelectedImageIndex] = useState(0)

    const SLIDER_IMAGES = projectDetails && projectDetails["imagesAttachments"] || []

    const disableProcessBtn = useMemo(() => {
        if (currentUser && projectDetails && projectDetails?.totalInvestmentValue && projectDetails?.totalProjectCost) {
            const calculateInvestment = (parseFloat(projectDetails?.totalInvestmentValue) / parseFloat(projectDetails?.totalProjectCost) * 100)
            if (calculateInvestment <= 100){
                return false
            }
        }
        return true
    }, [projectDetails])

    return (
        <div className=''>
            <div className='grid grid-cols-4 gap-4 p-2 bg-orange-100  '>
                {/* big image */}
                <div className='col-span-3  '>
                    <div className='flex flex-col space-y-4'>
                        {SLIDER_IMAGES[selectedImageIndex] && typeof SLIDER_IMAGES[selectedImageIndex] == "string" ? <img src={SLIDER_IMAGES[selectedImageIndex]} className='w-full h-[350px]' /> : <div className='w-full h-[350px] flex justify-center items-center'>
                            <div className='flex flex-col items-center'>
                                <ImageOff size={24} />
                                <div>No Content</div>
                            </div>
                        </div>}
                        <div className=''>
                            <Carousel className='px-2'>
                                <CarouselContent className=''>
                                    {
                                        SLIDER_IMAGES.map((_: any, indx: number) => {
                                            return <CarouselItem onClick={() => setSelectedImageIndex(indx)} key={`project-img-${indx + 1}`} className={cn("md:basis-1/2 lg:basis-1/6 cursor-pointer flex justify-center pl-4 ")}>
                                                <img src={SLIDER_IMAGES[indx]} className={cn('rounded-lg', { "p-1 border border-primary": selectedImageIndex == indx, "transition-padding duration-100 ease-in-out": true, })} alt={`project-img-${indx + 1}`} style={{ height: 80, width: 100 }} />
                                            </CarouselItem>
                                        })
                                    }

                                </CarouselContent>
                            </Carousel>
                        </div>
                    </div>
                </div>

                <div className='space-y-2' >
                    <div className='border border-black border-2   w-100 h-[100px] rounded-lg flex justify-between items-center '>
                        <div className='flex flex-col items-center space-y-1 mx-auto'>
                            <div><img src={ICON_PROJECT_SIZE} style={{ height: "20px", width: "16px" }} /></div>
                            <div className='text-xs'>Project size</div>
                            <div className='text-sm font-bold text-primary'>{projectDetails && projectDetails?.projectSize || undefined} KWh</div>
                        </div>
                    </div>
                    <div className='border border-black border-2   w-100 h-[100px] rounded-lg flex justify-between items-center '>
                        <div className='flex flex-col items-center space-y-1 mx-auto'>
                            <div><img src={ICON_PROJECT_TYPE} style={{ height: "24px", width: "24px" }} /></div>
                            <div className='text-xs'>Project Type</div>
                            <div className='text-sm font-bold text-primary'>{projectDetails && projectDetails?.projectType || undefined}</div>
                        </div>
                    </div>
                    <div className='border border-black border-2   w-100 h-[100px] rounded-lg flex justify-between items-center '>
                        <div className='flex flex-col items-center space-y-1 mx-auto'>
                            <div><img src={ICON_ROOF_TOP} style={{ height: "20px", width: "64px" }} /></div>
                            <div className='text-xs'>System Type</div>
                            <div className='text-sm font-bold text-primary'>{projectDetails && projectDetails?.systemType || undefined}</div>
                        </div>
                    </div>
                    <div className='border border-black border-2   w-100 h-[100px] rounded-lg flex justify-between items-center '>
                        <div className='flex flex-col items-center space-y-1 mx-auto'>
                            <div><TvMinimalPlay size={24} className='text-primary' /></div>
                            {projectDetails?.videoAttachments ? <DocumentViwer url={projectDetails?.videoAttachments} title='Watch Video' /> : ""}
                        </div>
                    </div>
                </div>
            </div>
            <ViewProjectDetails projectDetails={projectDetails} />
            <div className='flex justify-center'>
                {currentUser?.role == USER_ROLES.INVESTOR ? <RoundedButton disabled={disableProcessBtn} className='mx-2 my-4' onClick={() => navigate("/" + MAIN_ROUTES_CONST.cell + "/" + CELL_ROUTES.investment_calculator + "/" + id)} >Proceed for Investment</RoundedButton> : null}
                {currentUser?.role == USER_ROLES.OFF_TAKER ? <RoundedButton disabled={!currentUser} className='mx-2 my-4' onClick={() => navigate("/" + MAIN_ROUTES_CONST.existing_enquiry_rquest + "/" + id)} >Enquiry Request</RoundedButton> : null}
                
            </div>
        </div >
    )
}

export default ProjectRightDetail