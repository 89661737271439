import React from 'react'
import { cn } from 'src/helpers'
import { ClassNameValue } from 'tailwind-merge'

type Props = {
    size?: number,
    classNames?: ClassNameValue,
    prefix?: string,
    suffix?: string,
    value?: string
}

const CustomTextIcon = ({ size = 16, classNames, prefix, suffix, value }: Props) => {
    return (
        <span className={cn(`p-0 items-center text-sm  text-gray-500 text-nowrap`, classNames)}>{prefix || ""}{value}{suffix || ""}</span>
    )
}

export default CustomTextIcon