import React from 'react'
import InvestmentCalculatorForm from 'src/modules/Investments/InvestmentCalculatorForm'
import AllInvestmentsTable from 'src/modules/Investments/InvestmentHistory'

type Props = {}

const InvestmentMain = (props: Props) => {
    return (
        <div className='relative '>
            <div className='h-[200px] bg-gray-100 rounded-b-3xl'></div>
            <div className='max-w-[80vw] mx-auto -mt-[100px] rounded-xl bg-white'>
                <InvestmentCalculatorForm />
                <AllInvestmentsTable />
            </div>
        </div>
    )
}

export default InvestmentMain