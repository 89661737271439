import React, { useEffect } from 'react'
import HeaderMain from './Header/HeaderMain'
import { Outlet, useLocation } from 'react-router-dom'
import FooterMain from './Footer/FooterMain'
import { cn } from 'src/helpers'
import { MAIN_ROUTES_CONST, RESTRICT_LAYOUT_PATH } from 'src/navigations/modules'
import { useAuth } from 'src/modules/Auth/Core'

type Props = {}

const DefaultLayoutMain = (props: Props) => {

    const { pathname } = useLocation()


    const { currentUser } = useAuth()


    const PATHS = [...RESTRICT_LAYOUT_PATH].map((d: any) => "/" + d)

    console.log({ PATHS, pathname });


    return (
        <div>
            <HeaderMain />
            <div className={cn('mt-[80px]', { "max-w-[80vw] mx-auto": PATHS.includes(pathname) || PATHS.some((path: any) => pathname.includes(path)) })}>
                <Outlet />
            </div>
            <FooterMain />
        </div>
    )
}

export default DefaultLayoutMain