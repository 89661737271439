import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { catchAsync, successTheme } from 'src/helpers'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import { loginRequestApi } from 'src/services/requests/Auth'
import { ILoginResponse } from 'src/services/requests/Auth/modules'
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { login_initialValues, LOGIN_FORM_FIELDS, loginFormValidationSchema } from './modules'
import { useAuth } from './Core'
import { toast } from 'sonner'
import { useState } from 'react'

type Props = {
    isOpenDialog?: (e: any) => void
}

const Login = ({ isOpenDialog }: Props) => {

    const navigate = useNavigate()
    const { setCurrentUser, saveAuth } = useAuth()
    const [isLoading, setisLoading] = useState(false)

    const formik = useFormik({
        initialValues: login_initialValues,
        validationSchema: loginFormValidationSchema,
        onSubmit: (values, { setSubmitting }) => {
            // Handle form submission here
            handleLoginForm()
        },
    });

    console.log({ values: formik.values, error: formik.errors })

    const handleLoginForm = catchAsync(
        async () => {
            setisLoading(true)
            return await loginRequestApi(formik.values);
        },
        (result: ILoginResponse) => {
            if (result.data.accessToken) {
                const { accessToken, userid, email, role, firstName, lastName, _id } = result.data

                saveAuth({
                    api_token: accessToken,
                    user_id: _id
                })

                setCurrentUser({
                    email: email,
                    id: _id,
                    name: firstName + " " + lastName,
                    access_token: accessToken,
                    role: role,
                    user_id: userid
                })
                setisLoading(false)
                toast.success("Login Successfully", { style: successTheme })
                navigate("/" + MAIN_ROUTES_CONST.dashboard)
                isOpenDialog && isOpenDialog(false)
            }

            console.log('Login successful:', result);
        }, () => {
            setisLoading(false)
        }
    );

    const handleForgotClick = () => {
        isOpenDialog && isOpenDialog(false)
        navigate(MAIN_ROUTES_CONST.forgot)
    }

    return (
        <div className='space-y-4'>
            <div className=' font-bold uppercase text-primary'>Login</div>
            <div className='text-sm'>If you already have an account, please sign in.</div>
            <div>
                <form className='space-y-2' onSubmit={formik.handleSubmit}>
                    <div className='space-y-2'>
                        {
                            LOGIN_FORM_FIELDS.map((field: any) => {
                                return <RenderFormComponents key={`login-form-${field.dataKey}`} formik={formik} value={formik.values[field.dataKey as keyof object]} {...field} onChange={(e: any) => formik.setFieldValue(field.dataKey, e)} />
                            })
                        }
                    </div>
                    <div className='text-sm text-end text-blue-600'><span className='cursor-pointer' onClick={handleForgotClick}>Forgot Password</span> </div>
                    <div><RoundedButton disabled={isLoading} type='submit' className='w-full' isLoading={isLoading} >Login</RoundedButton></div>
                </form>
            </div>
        </div>
    )
}

export default Login