import { AxiosResponse } from "axios";
import { getAuth } from "src/modules/Auth/Core";
import { axiosInstance } from "src/services/axiosSetup";
import * as API_URL from "src/services/consts/dashboard"


export function getInvestorSummaryRequestApi(): Promise<any> {
  const auth = getAuth()
  return axiosInstance.get(`${API_URL.INVESTOR_SUMMARY}/${auth?.user_id}`).then((d: AxiosResponse) => d.data);
}

export function getInvestorOverviewRequestApi(type: string, query: string): Promise<any> {
  const auth = getAuth()
  let url = null
  if (type == "weekly") {
    url = API_URL.INVESTOR_OVERVIEW_WEEKLY
  }
  else if (type == "monthly") {
    url = API_URL.INVESTOR_OVERVIEW_MONTHLY
  }
  else if (type == "yearly") {
    url = API_URL.INVESTOR_OVERVIEW_YEARLY
  }
  return axiosInstance.get(`${url}?${query}`).then((d: AxiosResponse) => d.data);
}


export function getInvestorHistoryRequestApi(): Promise<any> {
  const auth = getAuth()
  return axiosInstance.get(`${API_URL.INVESTOR_HISTORY}/${auth?.user_id}`).then((d: AxiosResponse) => d.data);
}
