import React, { useState } from 'react'
import BrandComponent from '../../components/BrandComponent'
import NavigationMenu from './NavigationMenu'
import { Button, Separator } from 'src/shadcn/components/ui'
import Modal from 'src/shadcn/components/custom/Modal'
import Login from 'src/modules/Auth/Login'
import Registration from 'src/modules/Auth/Registration'
import { useAuth } from 'src/modules/Auth/Core'
import { User, Wallet } from 'lucide-react'
import UserComponent from './UserComponent'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/store'
import { getTempDataRootState, setShowLoginModule } from 'src/redux/reducers/tempDataSlice'
import { useDispatch } from 'react-redux'
import { ICON_WALLET } from 'src/helpers'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES_CONST, MAIN_ROUTES_CONST } from 'src/navigations/modules'

type Props = {}

const HeaderMain = (props: Props) => {

  const [openAuthDialog, setopenAuthDialog] = useState(false)
  const { showLoginModal } = useSelector((state: RootState) => getTempDataRootState(state))
  const dispatch = useDispatch()
  const { currentUser } = useAuth()
  const navigate = useNavigate()
  // const currentUser =  true

  return (
    <div className='flex justify-between items-center h-[80px] shadow-md fixed top-0 left-0 w-full bg-white z-[80] px-4'>
      <BrandComponent />
      <div className='flex space-x-4'>
        <NavigationMenu />
        {currentUser ?
          <div className='flex space-x-2 items-center px-2'>
            <button className='' onClick={() => navigate("/"+ MAIN_ROUTES_CONST.wallet)}>
              <div className='flex items-center space-x-2'>
                <img src={ICON_WALLET} style={{ width: "40px", height: "40px" }} />
                <span>₹ 0</span>
              </div>
            </button>
            <Separator orientation='vertical' />
            <UserComponent />
          </div> :
          <Button size={"sm"} className='rounded-full ' onClick={() => setopenAuthDialog(true)} >Login / Registration</Button>
        }

        <Modal isOpen={openAuthDialog || showLoginModal} className={"max-w-4xl"} onChangeIsOpen={(e: any) => {
          setopenAuthDialog(e)
          dispatch(setShowLoginModule(e))
        }}  >
          <div className='grid grid-cols-2 gap-4'>
            <div><Login isOpenDialog={setopenAuthDialog} /></div>
            <div><Registration isOpenDialog={setopenAuthDialog} /></div>
          </div>
        </Modal>
      </div>
    </div>
  )
}

export default HeaderMain