import * as React from "react"
import * as SwitchPrimitives from "@radix-ui/react-switch"
import { cn } from "src/helpers"


const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className,onCheckedChange, ...props }, ref) => {

  const [isChecked, setIsChecked] = React.useState(false);

  return (
    <div className="relative">
      <SwitchPrimitives.Root
        className={cn(
          "peer inline-flex h-5 w-12 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent shadow-sm transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=unchecked]:bg-input",
          className
        )}
        onCheckedChange={(e:any)=>{
          setIsChecked(e)
          onCheckedChange && onCheckedChange(e)
        }}
        {...props}
        ref={ref}
      >
         {isChecked ? (
           <span className="absolute text-xs top-[2px] left-2 text-white">ON</span>
          
        ) : (
          <span className="absolute text-xs top-[2px] right-2">OFF</span>
        )}
        <SwitchPrimitives.Thumb
          className={cn(
            "pointer-events-none block h-4 w-4 rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-7 data-[state=unchecked]:translate-x-0"
          )}
        />
      </SwitchPrimitives.Root>
    </div>
  )
})
Switch.displayName = SwitchPrimitives.Root.displayName

export { Switch }
