import { Outlet } from 'react-router-dom';
import './App.css';
import { AuthInit } from './modules/Auth/Core';

function App() {
  return (
    <AuthInit>
      <Outlet />
    </AuthInit>
  );
}

export default App;
