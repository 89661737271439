import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'src/modules/Auth/Core'
import ChangePassword from 'src/modules/Profile/ChangePassword'
import CompanySettings from 'src/modules/Profile/CompanySettings'
import PrivacySettings from 'src/modules/Profile/PrivacySettings'
import ProfileComponent from 'src/modules/Profile/ProfileComponent'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'src/shadcn/components/ui'

type Props = {}

const ProfileMain = (props: Props) => {

    const { currentUser } = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
      if(!currentUser){
        navigate("/")
      }
      return () => {}
    }, [currentUser])
    

    return (
        <div>
            <div className='relative '>
                <div className='h-[200px] bg-gray-100 rounded-b-3xl  '></div>
                <div className='max-w-[80vw] mx-auto -mt-[100px] mb-4 rounded-xl bg-white p-6 space-y-4 shadow-xl'>
                    <Tabs defaultValue="profile" className="w-full">
                        <TabsList>
                            <TabsTrigger value="profile">Profile</TabsTrigger>
                            {/* <TabsTrigger value="company_setting">Company Setting</TabsTrigger> */}
                            <TabsTrigger value="change_password">Change Password</TabsTrigger>
                            <TabsTrigger value="privacy_setting">Privacy Setting</TabsTrigger>
                        </TabsList>
                        <TabsContent value="profile">
                            <ProfileComponent />
                        </TabsContent>
                        {/* <TabsContent value="company_setting">
                            <CompanySettings />
                        </TabsContent> */}
                        <TabsContent value="change_password">
                            <ChangePassword />
                        </TabsContent>
                        <TabsContent value="privacy_setting">
                            <PrivacySettings />
                        </TabsContent>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}

export default ProfileMain