import React from 'react'
import VerifyOtp from 'src/modules/Auth/VerifyOtp'

type Props = {}

const VerifyOtpMain = (props: Props) => {
    return (
        <div className=''>
            <div className='w-[500px] mx-auto p-[48px] shadow-xl my-[50px]'>
                <VerifyOtp />
            </div>
        </div>
    )
}

export default VerifyOtpMain