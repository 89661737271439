import { ArrowUp } from 'lucide-react'
import React from 'react'

type Props = {}

const InvestmentListItem = (props: Props) => {
    return (
        <div className='grid grid-cols-3 text-xs border rounded-lg p-2'>
            <div className='col-span-2 '>
                <div className='flex space-x-1'>
                    <div><ArrowUp size={16} /></div>
                    <div className='flex flex-col'>
                        <div>Receive Via 1234</div>
                        <div className='text-gray-400'>12:30 PM</div>
                    </div>
                </div>
            </div>
            <div className='text-center'>
                ₹ 000.00
            </div>
        </div>
    )
}

export default InvestmentListItem