import { AVAILABLE_SPACE_UNITS_OPTIONS, BUILDING_TYPES, FormField, NAME_REGEX, NUMBER_REGEX, PHONE_NUMBER_REGEX, PROJECT_TYPES, PROPERTY_TYPE_OPTIONS, SYSTEM_TYPES } from 'src/helpers';
import * as Yup from 'yup';

export interface IEnquiryForm1 {
    projectType: string;
    systemType: string;
    unitRequiredPerMonth: string;
    unitConsumptionPerDay: string;
    propertyType: string;
    buildingType: string;
    contactPersonName: string;
    contactPersonNumber: string;
    country: string;
    city: string;
    state: string;
    address: string;
    pinCode: string;
    tariffRate: string;
    monthlyBillAmount: string;
    availableSpaceSQ: string;
    imagesAttachment: File[];
    videoAttachment: string;
}


export const initialEnquiryForm1: IEnquiryForm1 = {
    projectType: '',
    systemType: '',
    unitRequiredPerMonth: "",
    unitConsumptionPerDay: "",
    propertyType: '',
    buildingType: '',
    contactPersonName: '',
    contactPersonNumber: '',
    country: '',
    city: '',
    state: '',
    address: '',
    pinCode: '',
    tariffRate: "",
    monthlyBillAmount: "",
    availableSpaceSQ: "",
    imagesAttachment: [],
    videoAttachment: ""
};


export const enquiryForm1Schema = Yup.object().shape({
    projectType: Yup.string().required('Project type is required'),
    systemType: Yup.string().required('System type is required'),
    unitRequiredPerMonth: Yup.string().required("Unit required per month is required"),
    unitConsumptionPerDay: Yup.string().required("Unit consumption per day is required"),
    propertyType: Yup.string().required('Property type is requi     red'),
    buildingType: Yup.string().required('Building type is required'),
    contactPersonName: Yup.string().required('Contact person name is required'),
    contactPersonNumber: Yup.string().required('Contact person number is required'),
    country: Yup.string().required('Country is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    address: Yup.string().required('Address is required'),
    pinCode: Yup.string().required('Pin code is required'),
    tariffRate: Yup.number().required('Tariff rate is required'),
    monthlyBillAmount: Yup.number().required('Monthly bill amount is required'),
    availableSpaceSQ: Yup.number().required('Available space (SQ) is required'),
    imagesAttachment: Yup.array().of(Yup.mixed().required('Images attachment is required')),
    videoAttachment: Yup.mixed().required('Video attachment is required'),
});


export const ENQUIRY_FORM_1_FIELDS: FormField[] = [
    {
        label: 'Project Type',
        dataKey: 'projectType',
        componentType: 'options',
        componentProps: {
            options: PROJECT_TYPES
        },
    },
    {
        label: 'System Type',
        dataKey: 'systemType',
        componentType: 'options',
        componentProps: {
            options:SYSTEM_TYPES
        },
    },
    {
        label: 'Unit Required Per Month',
        dataKey: 'unitRequiredPerMonth',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: NUMBER_REGEX,
            placeholder: 'Units',
            numberFormatted:true
        },
    },
    {
        label: 'Unit Consumption Per Day',
        dataKey: 'unitConsumptionPerDay',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: NUMBER_REGEX,
            placeholder: 'Enter unit consumption per day',
            numberFormatted:true
        },
    },
    {
        label: 'Property Type',
        dataKey: 'propertyType',
        componentType: 'options',
        componentProps: {
            options: PROPERTY_TYPE_OPTIONS
        },
    },
    {
        label: 'Building Type',
        dataKey: 'buildingType',
        componentType: 'options',
        componentProps: {
            options: BUILDING_TYPES
        },
    },
    {
        label: 'Contact Person Name',
        dataKey: 'contactPersonName',
        componentType: 'input',
        componentProps: {
            type: 'text',
            placeholder: 'Enter contact person name',
            regex:NAME_REGEX,
        },
    },
    {
        label: 'Contact Person Number',
        dataKey: 'contactPersonNumber',
        componentType: 'input',
        componentProps: {
            type: 'text',
            placeholder: 'Enter contact person number',
            regex:PHONE_NUMBER_REGEX,
            maxLength:10
        },
    },
    {
        label: 'Country',
        dataKey: 'country',
        componentType: 'options',
        componentProps: {
            options: [
                { label: 'India', value: 'India' },
            ],
        },
    },
    {
        label: 'City',
        dataKey: 'city',
        componentType: 'options',
        componentProps: {
            options: [
                { label: 'Thane', value: 'Thane' },
                { label: 'Mumbai', value: 'Mumbai' },
                { label: 'Noida', value: 'Noida' },
                // Add more options as needed
            ],
        },
    },
    {
        label: 'State',
        dataKey: 'state',
        componentType: 'options',
        componentProps: {
            options: [
                { label: 'Maharashtra', value: 'state1' },
                { label: 'UP', value: 'UP' },
                // Add more options as needed
            ],
        },
    },
    {
        label: 'Address',
        dataKey: 'address',
        componentType: 'input',
        componentProps: {
            type: 'text',
            placeholder: 'Enter address',
        },
    },
    {
        label: 'Pin Code',
        dataKey: 'pinCode',
        componentType: 'input',
        componentProps: {
            type: 'text',
            placeholder: 'Enter pin code',
            regex:NUMBER_REGEX,
            maxLength:6
        },
    },
    {
        label: 'Tariff Rate',
        dataKey: 'tariffRate',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: NUMBER_REGEX,
            placeholder: 'Enter tariff rate',
        },
    },
    {
        label: 'Monthly Bill Amount',
        dataKey: 'monthlyBillAmount',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: NUMBER_REGEX,
            placeholder: 'Enter monthly bill amount',
            numberFormatted:true
        },
    },
    {
        label: 'Available Space (SQ)',
        dataKey: 'availableSpaceSQ',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: NUMBER_REGEX,
            placeholder: 'Enter available space (SQ)',
            numberFormatted:true,
            options:AVAILABLE_SPACE_UNITS_OPTIONS
        },
    },
    {
        label: 'Images Attachment',
        dataKey: 'imagesAttachment',
        componentType: 'file_picker',
        componentProps: {
            fileSelectionType: 'multi',
            placeholder: 'Select images',
        },
    },
    {
        label: 'Video Attachment',
        dataKey: 'videoAttachment',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Select video',
        },
    },
];
