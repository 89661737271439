import { useFormik } from 'formik'
import { KeyRound } from 'lucide-react'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import InputWithIcon from 'src/shadcn/components/custom/InputWithIcon'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { Label } from 'src/shadcn/components/ui'
import { RESET_PASSWORD_FORM_FIELDS, resetpassword_initialValues, ResetPasswordFormValidationSchema } from './modules'
import { catchAsync, successTheme } from 'src/helpers'
import { AxiosResponse } from 'axios'
import { toast } from 'sonner'
import { getTempDataRootState, resetEmailForValidatOtp, resetSession, setEmailForValidatOtp } from 'src/redux/reducers/tempDataSlice'
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents'
import { changePasswordRequestApi } from 'src/services/requests/Auth'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/store'

type Props = {}

const ResetPassword = (props: Props) => {

    const [isLoading, setisLoading] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { email_for_validate_otp, session } = useSelector((state: RootState) => getTempDataRootState(state))


    const formik = useFormik({
        initialValues: resetpassword_initialValues,
        validationSchema: ResetPasswordFormValidationSchema,
        onSubmit: (values, { setSubmitting }) => {
            // Handle form submission here
            handleResetPasswordForm()
        },
    });


    const handleResetPasswordForm = catchAsync(
        async () => {
            setisLoading(true)
            return await changePasswordRequestApi({
                ...formik.values,
                email:email_for_validate_otp
            });
        },
        (result: AxiosResponse) => {
            if (result.status == 200) {
                dispatch(resetEmailForValidatOtp())
                dispatch(resetSession())
                formik.resetForm()
                toast.success("Password reset successful, You can login now.", { style: successTheme })
                navigate("/")
            }
            setisLoading(false)
        },
        () => {
            setisLoading(false)
        }
    );

    return (
        <div>
            <div className='space-y-4'>
                <div className=' font-bold uppercase text-primary'>RESET PASSWORD</div>
                <div className='text-xs'>Set new password for log in</div>
                <form className='space-y-2' onSubmit={formik.handleSubmit}>
                    <div className='space-y-2'>
                        {
                            RESET_PASSWORD_FORM_FIELDS.map((field: any) => {
                                return <RenderFormComponents key={`reset-password-form-${field.dataKey}`} formik={formik} value={formik.values[field.dataKey as keyof object]} {...field} onChange={(e: any) => formik.setFieldValue(field.dataKey, e)} />
                            })
                        }
                    </div>
                    <div><RoundedButton isLoading={isLoading} type='submit' className='w-full'>Submit</RoundedButton></div>
                </form>
            </div>
        </div>
    )
}

export default ResetPassword