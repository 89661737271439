import FilterMain from 'src/modules/Marketplace/Filters/FilterMain'
import React, { useEffect, useState } from 'react'
import ResultFilter from 'src/modules/Marketplace/Result/ResultFilter'
import { Separator } from 'src/shadcn/components/ui/separator'
import { catchAsync, stringifyObjectToRequestQuery } from 'src/helpers'
import { getAllProjectsRequestApi } from 'src/services/requests/Projects'
import { AxiosResponse } from 'axios'
import { Skeleton } from 'src/shadcn/components/ui/skeleton'
import ProjectResultsLoader from 'src/modules/Marketplace/Loaders/ProjectResultsLoader'

type Props = {}

const MarketplaceMain = (props: Props) => {



  const [projects, setprojects] = useState([])
  const [isLoading, setisLoading] = useState(false)

  useEffect(() => {
    handleGetAllProjects()
    return () => { }
  }, [])


  const handleGetAllProjects = catchAsync(async (query?: string) => {
    setisLoading(true)
    return await getAllProjectsRequestApi(query)
  }, (results: AxiosResponse) => {
    setprojects(results.data)
    setisLoading(false)
  }, () => {
    setisLoading(false)
  })

  const handleFilters = (e: any) => {
    delete e["price"]
    console.log({ filter: e });
    let prepareQueryString = stringifyObjectToRequestQuery(e)
    handleGetAllProjects(prepareQueryString)
  }


  return (
    <div className=''>
      <div className='grid grid-cols-4 gap-6'>
        <div className='p-2'>
          <FilterMain onChange={handleFilters} />
        </div>
        <div className='col-span-3 p-2 space-y-2 '>
          <div className='flex justify-between items-center'>
            <div className='uppercase text-primary font-bold'>Projects</div>
            <div className='flex space-x-2 text-xs'>Total : <span></span>{projects.length}<span></span></div>
          </div>
          {
            isLoading ? <div className='flex flex-col space-y-2'>
              {
                [...new Array(3)].map((_) => {
                  return <ProjectResultsLoader />
                })
              }
            </div> : <ResultFilter projects={projects} loading={isLoading} />
          }
        </div>
        
      </div>
    </div>
  )
}

export default MarketplaceMain