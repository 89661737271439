import { ColumnDef } from "@tanstack/react-table";
import { Task } from "../data/schema";
import { DataTableColumnHeader } from "./data-table-column-header";
import { DataTableRowActions } from "./data-table-row-actions";


export const columns: ColumnDef<Task>[] = [

  {
    accessorKey: 'transactions',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Transactions" />
    ),
  },
  {
    accessorKey: 'finalReturn',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Final Return" />
    ),
  },
  {
    accessorKey: 'yearTillReturn',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Year Till Return (%)" />
    ),
  },
  {
    accessorKey: 'investmentGain',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Investment Gain" />
    ),
  },
  

];
