import { useFormik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import { INVESTMENT_CALCULATOR_FORM_FIELDS, INVESTMENT_RESULT_FORM_FIELDS, investmentCalculatorFormValidationSchema, InvestmentDetailsFormInit, InvestmentDetailsFormResult } from './module'
import { catchAsync, FormField } from 'src/helpers'
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents'
import { Label } from 'src/shadcn/components/ui'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { useNavigate, useParams } from 'react-router-dom'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import { CELL_ROUTES } from 'src/navigations/CellRoutes'
import { useDispatch } from 'react-redux'
import { getProjectDetailsRootState, setInvestment } from 'src/redux/reducers/projectDetailsSlice'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/store'
import { getInvestmentCalculationsRequestApi } from 'src/services/requests/investments'
import { InvestmentReturnResponse } from 'src/services/requests/investments/modules'

type Props = {}

const InvestmentCalculator = (props: Props) => {


    const dispatch = useDispatch()
    const { calculateInvestment, project_details } = useSelector((state: RootState) => getProjectDetailsRootState(state))
    const { expectedReturnPerYear, totalInvestment, yearTillReturn, totalInterestReturn, investmentGain } = calculateInvestment
    const [isLoading, setisLoading] = useState(false)

    const navigate = useNavigate()
    const { id } = useParams()
    const formik = useFormik({
        initialValues: InvestmentDetailsFormInit,
        validationSchema: investmentCalculatorFormValidationSchema,
        onSubmit(values, formikHelpers) {
            // dispatch(setInvestment({
            //     totalInvestment: values.investmentAmount,
            // }))
            if (values?.investmentAmount && parseFloat(values?.investmentAmount)) {
                let requiredAmt = Math.max(Number((parseFloat(project_details?.totalProjectCost) - parseFloat(project_details?.totalInvestmentValue))) || 0, 0)
                if (parseFloat(values?.investmentAmount) <= requiredAmt) {
                    handleGetCalculation()
                }
                else {
                    formik.setFieldError("investmentAmount", "can not invest more than ₹ " +requiredAmt )
                }
            }
        },
    })

    useEffect(() => {
        if (formik.values.investmentAmount == 0) {
            formik.setFieldValue("investmentAmount", totalInvestment)
        }
        return () => { }
    }, [totalInvestment])



    const investmentResultFormik = useFormik({
        initialValues: InvestmentDetailsFormResult,
        onSubmit(values, formikHelpers) {

        },
    })

    const handleGetCalculation = catchAsync(async () => {
        setisLoading(true)
        return formik.values.investmentAmount && await getInvestmentCalculationsRequestApi({ investment_amount: formik.values.investmentAmount && Number(formik.values.investmentAmount).toString().replace(/[^A-z0-9.]+/g, "") })
    }, (result: InvestmentReturnResponse) => {
        if (result.success) {
            const { estimated_return_per_year, investment_amount, expected_irr, total_return_after72_months, total_time_period } = result.data

            const yearTillReturn = Number(total_time_period) / 12
            investmentResultFormik.setFieldValue("totalInterestReturn", expected_irr)
            // investmentResultFormik.setFieldValue("investmentGain", estimated_return_per_year)
            investmentResultFormik.setFieldValue("expectedReturnPerYear", estimated_return_per_year)
            investmentResultFormik.setFieldValue("finalReturn", total_return_after72_months)
            investmentResultFormik.setFieldValue("yearTillReturn", yearTillReturn)

            dispatch(setInvestment({
                totalInvestment: formik.values.investmentAmount,
                totalInterestReturn: expected_irr,
                investmentGain: 0,
                expectedReturnPerYear: estimated_return_per_year,
                finalReturn: total_return_after72_months,
                yearTillReturn: yearTillReturn,
            }))

            setisLoading(false)
        }
    }, () => { setisLoading(false) })

    useEffect(() => {
        investmentResultFormik.setFieldValue("investmentGain", investmentGain)
        investmentResultFormik.setFieldValue("totalInterestReturn", totalInterestReturn)
        investmentResultFormik.setFieldValue("expectedReturnPerYear", expectedReturnPerYear)

        return () => { }
    }, [expectedReturnPerYear, investmentGain, totalInterestReturn])



    return (
        <div className='space-y-4 p-4 bg-orange-50'>
            <div className='uppercase text-primary font-semibold border-primary border-b-2 border-l-0 border-r-0 border-t-0 '>Return Of Investment calculator</div>
            <form onSubmit={formik.handleSubmit}>
                <div className=''>
                    {
                        INVESTMENT_CALCULATOR_FORM_FIELDS && INVESTMENT_CALCULATOR_FORM_FIELDS.map((field: FormField, indx: number) => {
                            let oldFiled = { ...field };
                            delete oldFiled["label" as keyof object]
                            return <div key={`investment-calculator-form-filed-${oldFiled.dataKey}`} className='grid grid-cols-2 gap-4'>
                                <div className='py-4'><Label >{INVESTMENT_CALCULATOR_FORM_FIELDS[indx]?.label}</Label></div>
                                <RenderFormComponents {...oldFiled} formik={formik} value={formik.values[field.dataKey as keyof object]} onChange={(e: any) => formik.setFieldValue(field.dataKey, e)} />
                            </div>
                        })
                    }
                </div>
                <div className='flex justify-center mt-4'><RoundedButton isLoading={isLoading} className='mx-auto w-[150px]'>Submit</RoundedButton></div>
            </form>
            <div className='uppercase text-primary font-semibold border-primary border-b-2 border-l-0 border-r-0 border-t-0 '>Calculation result</div>
            <div>
                {
                    INVESTMENT_RESULT_FORM_FIELDS && INVESTMENT_RESULT_FORM_FIELDS.map((field: FormField, indx: number) => {
                        let oldFiled = { ...field };
                        delete oldFiled["label" as keyof object]
                        return <div key={`investment-result-form-filed-${oldFiled.dataKey}`} className='grid grid-cols-2 gap-4'>
                            <div className='py-4'><Label >{INVESTMENT_RESULT_FORM_FIELDS[indx]?.label}</Label></div>
                            <RenderFormComponents {...oldFiled} formik={investmentResultFormik} value={calculateInvestment[field.dataKey as keyof object]} onChange={(e: any) => investmentResultFormik.setFieldValue(field.dataKey, e)} />
                        </div>
                    })
                }
            </div>
            <div className=' flex justify-center space-x-2'>
                <RoundedButton className='w-[150px]' onClick={() => navigate(-1)} variant={'outline'}>Back</RoundedButton> <RoundedButton onClick={() => navigate("/" + MAIN_ROUTES_CONST.cell + "/" + CELL_ROUTES.review + "/" + id)} disabled={Object.entries(investmentResultFormik.values).some(([key, value]) => value == null)} className='w-[150px]'>Confirm</RoundedButton>
            </div>
        </div>
    )
}

export default InvestmentCalculator