import { useEffect, useState } from 'react'
import { catchAsync } from 'src/helpers'
import { columns } from './Table/components/columns'
import { DataTable } from './Table/components/data-table'

import { AxiosResponse } from 'axios'

type Props = {}


const Main = () => {


    const [tableData, settableData] = useState([])

    // useEffect(() => {
    //     handle_get_all_projects()
    //     return () => {}
    // }, [])


    // const handle_get_all_projects = catchAsync(
    //     async () => {
    //         return {}
    //     },
    //     (result: AxiosResponse) => {
    //         settableData(result.data)
    //     }
    // );


    return <div>
        <DataTable data={tableData || []} columns={columns} />
    </div>
}

const AllWalletHistoryTable = (props: Props) => {
    return (
        <div className='space-y-2'>
            <div className='text-primary uppercase font-semibold'>Wallet History</div>
            <Main />
        </div>
    )
}

export default AllWalletHistoryTable