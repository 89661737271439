import React from 'react'
import { ReactSVG } from 'react-svg'
import { toAbsoluteUrl } from 'src/helpers'
import { Svg } from 'src/helpers/SVGC'

type Props = {}

const BRAND_ICON_SM = "/media/svg/brand/brand-sm.svg"
const BRAND_ICON_MD = "/media/svg/brand/brand-md.svg"

const BrandComponent = (props: Props) => {

    return (
        <div>
            <div className='hidden lg:block'><ReactSVG src={toAbsoluteUrl(BRAND_ICON_MD)} /></div>
            <div className='hidden max-md:block'><ReactSVG src={toAbsoluteUrl(BRAND_ICON_SM)} /></div>
        </div>
    )
}

export default BrandComponent