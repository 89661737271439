import React from 'react'
import { Skeleton } from 'src/shadcn/components/ui/skeleton'

type Props = {}

const ProjectResultsLoader = (props: Props) => {
    return (
        <div className="flex space-x-4 w-100 border rounded-xl ">
            <Skeleton className="h-[200px] w-[250px] rounded-xl" />
            <div className='w-full flex flex-col space-y-4 space-x-2 p-4'>
                <div className='flex justify-between'>
                    <div className="space-y-2">
                        <Skeleton className="h-4 w-[150px]" />
                        <Skeleton className="h-4 w-[150px]" />
                    </div>
                    <div className="space-y-2">
                        <Skeleton className="h-4 w-[100px]" />
                    </div>
                </div>
                <div className='flex justify-evenly'>
                    <div className='border p-2 flex  flex-col space-y-2 rounded '>
                        <Skeleton className="h-[20px] w-[100px]" />
                        <Skeleton className="h-[20px] w-[100px]" />
                    </div>
                    <div className='border p-2 flex  flex-col space-y-2 rounded '>
                        <Skeleton className="h-[20px] w-[100px]" />
                        <Skeleton className="h-[20px] w-[100px]" />
                    </div>
                    <div className='border p-2 flex  flex-col space-y-2 rounded '>
                        <Skeleton className="h-[20px] w-[100px]" />
                        <Skeleton className="h-[20px] w-[100px]" />
                    </div>
                </div>
                <div className='flex justify-center'>
                    <Skeleton className="h-[20px] w-[100px]" />
                </div>
            </div>
        </div>
    )
}

export default ProjectResultsLoader