import React from 'react'
import * as modules from './modules'
import RenderData from '../RenderData'
import DocumentViwer from 'src/shadcn/components/custom/DocumentViwer'
import { checkDecimal } from 'src/helpers'

type Props = {
  projectDetails: any
}

const ViewProjectDetails = ({ projectDetails }: Props) => {

  const { DATA_SEQUENCE, PROJECT_FORM_FIELDS } = modules

  return (
    <div className='flex flex-col space-y-2 text-xs'>
      {
        Object.entries(DATA_SEQUENCE).map(([key, value], indx: number) => {
          return <div key={`${key}-${indx}`} className='grid grid-cols-3 gap-4 p-2 bg-orange-100 my-2 '>
            <div className='col-span-2'>
              <div className='text-gray-600 text-primary col-span-2 border border-primary border-l-0 border-r-0 border-t-0 border-b-2 uppercase font-bold mt-2'>{value}</div>
              {
                Object.keys(modules[key as keyof object]).filter((k: any) => !(["imagesAttachments", "headerImage", "videoAttachments"].includes(k))).map((k: any) => {
                  var newVal = projectDetails && Object.keys(projectDetails).includes(k) ? projectDetails[k] : null
                  let filed= PROJECT_FORM_FIELDS.find((d: any) => d.dataKey == k) 
                
                  if (newVal && filed?.componentProps?.numberFormatted) {
                    newVal = checkDecimal(newVal, true)
                    if(filed?.dataKey == "projectCost"){
                      console.log({newVal,filed,f : checkDecimal(newVal, true)})
                    }
                  }
                 
                  return <div key={`${key}-${k}`} className='grid grid-cols-2 gap-2 my-2'>
                    <div className='text-gray-600'>{filed?.label || "-"}</div>
                    <div className='flex space-x-2' >{
                    Object.keys(modules.initialAttachments).includes(k) && projectDetails && projectDetails[k] ? 
                    <DocumentViwer url={projectDetails[k]} /> : 
                    <RenderData value={projectDetails ? newVal : undefined} />}</div>
                  </div>
                })
              }
            </div>
          </div>
        })
      }
    </div>
  )
}

export default ViewProjectDetails